import { Fragment } from 'preact';
import { FunctionalComponent, h } from 'preact';
import { route } from 'preact-router';
import Match, { Link } from 'preact-router/match';
import { useCallback, useState } from 'preact/hooks';
import { LanguageService } from '../../services/language.service';
import { SessionService } from '../../services/session.service';
import Button from '../button';
import style from './style.css';


const Header: FunctionalComponent = () => {
    const ls = new LanguageService();
    const [language, setValue] = useState(ls.getLanguage());
    const updateLang = useCallback((event: any) => {
        let newVal = event.target.value;
        setValue(newVal);
        ls.setLanguage(newVal);
        window.location.reload();
    }, [language]);

    const [showProfile, setShowProfile] = useState(false);

    const session = new SessionService();
    const [authenticated, setAuthenticated] = useState(session.authenticated());
    const [userInfo, setUserInfo] = useState(session.userInfo());

    session.watchAuth = (value: boolean) => {
        setAuthenticated(value);
        setUserInfo(session.userInfo());
    }

    const logout = () => {
        session.logout();
        setShowProfile(false);
        route('/');
    }

    // Only shown when needed
    const ordersSubNav = (value: { url: string, path: string, matches: boolean }) => {
        if (!value.matches) {
            return false;
        }
        return (
            <nav class={style.subHeader}>
                <Link activeClassName={style.active} href="/new-order/regular">
                    {ls.translate('Regular Order')}
                </Link>
                {/* Hidden for now. We are launching regular orders only.*/}
                {/* <Link activeClassName={style.active} href="/new-order/unit-part">
                    {ls.translate('Unit Part Order')}
                </Link> */}
            </nav>
        );
    }

    // Only shown when /admin
    const adminSubNav = (value: { url: string, path: string, matches: boolean }) => {
        if (!value.matches) {
            return false;
        }
        return (
            <nav class={style.subHeader}>
                <Link activeClassName={style.active} href="/admin/users">
                    Users
                </Link>
                <Link activeClassName={style.active} href="/admin/customers">
                    Customers
                </Link>
                <Link activeClassName={style.active} href="/admin/order-status">
                    Order Status
                </Link>
                <Link activeClassName={style.active} href="/admin/lookups">
                    Lookups
                </Link>
            </nav>);
    }

    return (
        <Fragment>
            <header class={style.header}>
                <img src="/assets/logo.png" alt="Hino Logo" class={style.logo} />
                <nav style={{ display: authenticated === true ? 'block' : 'none' }}>
                    <Link activeClassName={style.active} href="/product/search">
                        {ls.translate('Product Search')}
                    </Link>
                    <Link activeClassName={style.active} path="/order/search/:subRoute?" href="/order/search">
                        {ls.translate('Order Search')}
                    </Link>
                    <Link activeClassName={style.active} path="/shipment/search/:subRoute?" href="/shipment/search">
                        {ls.translate('Shipment Search')}
                    </Link>
                    {/* Soft launch for orders. */}
                    {session.showOrders() && <Link activeClassName={style.active} path="/new-order/:subRoute" href="/new-order/regular">
                        {ls.translate('New Order')}
                    </Link>}
                    {session.isAdmin() ?
                        <Link activeClassName={style.active} path="/admin/:subRoute" href="/admin/users">
                            Admin
                        </Link> : <div></div>}
                </nav>
                <select value={language} onChange={updateLang} class={style.languageSelect}>
                    <option value="es">ES</option>
                    <option value="en">EN</option>
                </select>
                <div class={style.account} style={{ display: authenticated === true ? 'block' : 'none' }} onClick={() => setShowProfile(!showProfile)}>
                    <svg xmlns="http://www.w3.org/2000/svg" height="36" width="36"><path fill="#be1e2d" xmlns="http://www.w3.org/2000/svg" d="M20 19.958q-2.75 0-4.562-1.812-1.813-1.813-1.813-4.563t1.813-4.562Q17.25 7.208 20 7.208t4.562 1.813q1.813 1.812 1.813 4.562t-1.813 4.563Q22.75 19.958 20 19.958ZM6.667 33.333v-4.166q0-1.542.77-2.688.771-1.146 2.021-1.729 2.709-1.25 5.313-1.896 2.604-.646 5.229-.646t5.208.646q2.584.646 5.334 1.896 1.25.583 2.02 1.729.771 1.146.771 2.688v4.166Z" /></svg>
                </div>
                <div class={style.accountInfo + ' ' + (showProfile ? style.shown : style.hidden)}>
                    <p>{userInfo.userName}</p>
                    <p style="text-decoration: underline;">{userInfo.email}</p>
                    <p>{userInfo.customer}</p>
                    <Button text="Log Out" onClick={logout} />
                </div>
            </header>
            <Match path="/new-order/:subRoute">{(value: any) => ordersSubNav(value)}</Match>
            <Match path="/admin/:subRoute">{(value: any) => adminSubNav(value)}</Match>
        </Fragment>
    );


};

export default Header;
