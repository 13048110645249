import { Customer } from "../models";
import { HttpService } from "./http.service";

export class AdminService {
    private readonly apiPrefix = '/api';
    http: HttpService;
    constructor() {
        this.http = new HttpService();
    }

    userList() {
        return this.http.get<any[]>(`${this.apiPrefix}/admin/users`);
    }
    userDetail(userID: string | number) {
        return this.http.get<any>(`${this.apiPrefix}/admin/users/${userID}`)
    }
    userUpdatePassword(userID: string | number, newPassword: string) {
        return this.http.post<any>(`${this.apiPrefix}/admin/users/${userID}`, { newPassword: newPassword });
    }
    userCreate(customerID: string, email: string, name: string, password: string) {
        let payload = {
            CustomerID: +customerID,
            Email: email,
            Name: name,
            Password: password,
        }

        return this.http.post<any[]>(`${this.apiPrefix}/admin/users`, payload);
    }

    userDelete(userID: string) {
        return this.http.delete<any>(`${this.apiPrefix}/admin/users/${userID}`);
    }

    customerList() {
        return this.http.get<any[]>(`${this.apiPrefix}/admin/customers`);
    }

    customerDetail(customerID: string | number) {
        return this.http.get<Customer>(`${this.apiPrefix}/admin/customers/${customerID}`)
    }

    customerUpdate(customer: Customer) {
        return this.http.post<any>(`${this.apiPrefix}/admin/customers`, customer);
    }

    customerCreate(customer: Customer) {
        return this.http.post<any>(`${this.apiPrefix}/admin/customers/create`, customer);
    }

    customerOrdersList() {
        return this.http.get<any[]>(`${this.apiPrefix}/admin/orders`);
    }

    customerOrdersDownload(orderID: number) {
        return this.http.download(`${this.apiPrefix}/admin/orders/customer/${orderID}`, undefined, false);
    }

    unitPartOrdersDownload(orderID: number) {
        return this.http.download(`${this.apiPrefix}/admin/orders/unitpart/${orderID}`, undefined, false);
    }

    sourceCodeCreate(sourceCode: any) {
        return this.http.post(`${this.apiPrefix}/admin/lookups/sourcecode`, sourceCode);
    }

    sourceCodeUpdate(sourceCode: any) {
        return this.http.put(`${this.apiPrefix}/admin/lookups/sourcecode`, sourceCode);
    }

    sourceCodeDelete(sourceCodeID: number) {
        return this.http.delete(`${this.apiPrefix}/admin/lookups/sourcecode/${sourceCodeID}`);
    }
}