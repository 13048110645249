import { FunctionalComponent, h } from 'preact';
import { FormatterService } from '../../services/formatter.service';
import { LanguageService } from '../../services/language.service';
import style from './style.css';

const KitListModal: FunctionalComponent<{ isOpen: boolean, loading: boolean, items: any[], closeModal: () => void; }> = (props) => {
    const format = new FormatterService();
    const language = new LanguageService();
    return (
        <div class={style.modal} style={{ display: props.isOpen ? 'block' : 'none' }} onClick={props.closeModal}>
            <div class={style.modalContent} onClick={(event) => event.stopPropagation()}>
                <h1>{language.translate('Kit')} <span class={style.close} onClick={props.closeModal}>&times;</span></h1>
                {props.loading && <progress></progress>}
                {!props.loading &&
                    <table>
                        <thead>
                            <tr>
                                <th>{language.translate('Seq.')}</th>
                                <th>{language.translate('Kit Component')}</th>
                                <th>{language.translate('Description')}</th>
                                <th>{language.translate('Qty.')}</th>
                                <th>{language.translate('Price')}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {props.items.map(item =>
                                <tr key={item.Sequence}>
                                    <td>{item.Sequence}</td>
                                    <td>{item.PartNumber}</td>
                                    <td>{item.Description}</td>
                                    <td>{item.QtyRequired}</td>
                                    <td>{format.currency(item.ListPrice)}</td>
                                </tr>)
                            }
                            <tr class={style.totalrow}>
                                <td colSpan={4}>Total</td>
                                {/* 
                                    Sum the items' total price, which is calculated in the API as 
                                    QtyRequired * ListPrice. 
                                */}
                                <td>{format.currency(props.items.reduce((sum, item) => sum + item.TotalPrice, 0))}</td>
                            </tr>
                        </tbody>
                    </table>}
            </div>
        </div>
    );
}

export default KitListModal;