import { LanguageService } from "./language.service";
import { SessionService } from "./session.service";

/**
 * A convenience service to wrap HTTP requests.
 */
export class HttpService {
    session: SessionService;
    language: LanguageService;

    constructor() {
        this.session = new SessionService();
        this.language = new LanguageService();
    }

    /**
     * Get default headers. Will append auth header automatically if user is authenticated.
     */
    private defaultHeaders(): Headers {
        let headers = new Headers();
        headers.append('Accept', 'application/json');
        headers.append('X-Lang', this.language.getLanguage());
        // Get auth token and append if not empty.
        let token = this.session.getToken();
        if (token.length > 0) {
            headers.append("X-Auth", token);
        }
        return headers;
    }

    /**
     * Wrapper for fetch API.
     * @param url The URL you want to fetch
     * @param method The HTTP method (GET, HEAD, POST, PUT, DELETE, CONNECT, OPTIONS, TRACE, PATCH)
     * @param body Request body or null.
     */
    private fetch(url: string, method: string, body?: BodyInit | null) {
        const options = {
            method: method,
            headers: this.defaultHeaders(),
            body: body,
        };
        return fetch(url, options)
            .then(resp => {
                // check for error response
                if (!resp.ok) {
                    return Promise.reject(resp.status);
                }
                return resp.json();
            })
            .catch(err => Promise.reject(err));
    }

    /**
     * Use fetch API to http GET something.
     * @param url The URL you want to GET.
     */
    get<T>(url: string): Promise<T> {
        return this.fetch(url, 'GET');
    }

    /**
     * Use fetch API to http POST something.
     * @param url The URL you want to POST.
     */
    post<T>(url: string, body?: any): Promise<T> {
        // If we have an object, stringify it.
        if (typeof body === 'object') {
            body = JSON.stringify(body);
        }
        return this.fetch(url, 'POST', body);
    }

    /**
     * Use fetch API to http PUT something.
     * @param url The URL you want to PUT.
     */
    put<T>(url: string, body?: any): Promise<T> {
        // If we have an object, stringify it.
        if (typeof body === 'object') {
            body = JSON.stringify(body);
        }
        return this.fetch(url, 'PUT', body);
    }

    /**
     * Use fetch API to http DELETe something.
     * @param url The URL you want to DELETE.
     */
    delete<T>(url: string, body?: any): Promise<T> {
        // If we have an object, stringify it.
        if (typeof body === 'object') {
            body = JSON.stringify(body);
        }
        return this.fetch(url, 'DELETE', body);
    }

    upload(url: string, body: any) {
        const options = {
            method: 'POST',
            headers: this.defaultHeaders(),
            body: body,
        };
        return fetch(url, options)
            .then(resp => {
                console.log(resp);
                // check for error response
                if (!resp.ok) { return Promise.reject(resp.status); }
                return resp.text();
            })
            .catch(err => Promise.reject(err));
    }

    /**
     * Use fetch API to download a blob.
     * @param url The URL endpoint.
     * @param post Defaults to true ('POST'). If you pass false, will use 'GET.
     */
    download(url: string, body?: any, post = true): Promise<Blob> {
        // If we have an object, stringify it.
        if (typeof body === 'object') {
            body = JSON.stringify(body);
        }
        const options = {
            method: post ? 'POST' : 'GET',
            headers: this.defaultHeaders(),
            body: body,
        };
        return fetch(url, options)
            .then(resp => {
                // check for error response
                if (!resp.ok) { return Promise.reject(resp.status); }
                return resp.blob();
            })
            .catch(err => Promise.reject(err));
    }
}