import { FunctionalComponent, h } from 'preact';
import { route } from 'preact-router';
import { useEffect, useState } from 'preact/hooks';
import BackButton from '../../../../components/back-button';
import LoadingIndicator from '../../../../components/loading-indicator';
import { AdminService } from '../../../../services/admin.service';
import { LanguageService } from '../../../../services/language.service';
import { SessionService } from '../../../../services/session.service';

const AdminUserDetail: FunctionalComponent<any> = ({ id, ...props }) => {
    const language = new LanguageService();
    const admin = new AdminService();

    const [details, setDetails] = useState<any>();
    const session = new SessionService();
    if (session.authenticated() === false) {
        route('/');
    }

    const [loading, setLoading] = useState(true);
    const [initialLoad, setInitialLoad] = useState(false);
    useEffect(() => {
        admin.userDetail(id).then(user => {
            setDetails(user);
            setLoading(false);
        })
    }, [initialLoad]);

    const changePassword = () => {
        let updatedPW = prompt('New password:');
        if (!updatedPW || updatedPW.length == 0) {
            return;
        }
        admin.userUpdatePassword(id, updatedPW).then(() => {
            alert('Password updated.');
            route('/admin/users');
        });
    }

    const deleteUser = () => {
        if (confirm(`Are you sure you want to delete ${details?.Name}?`)) {
            admin.userDelete(id).then(() => {
                alert('User deleted.');
                route('/admin/users');
            })
        }
    }

    return (
        <div>
            <LoadingIndicator loading={loading}>
            <h1><BackButton />{language.translate('Users / ') + details?.Name}</h1>
                <p>Email: {details?.Email}</p>
                <p>Customer #: {details?.CustomerID}</p>
                <p><button onClick={changePassword}>Change Password</button></p>
                <p><button onClick={deleteUser}>Delete User</button></p>
            </LoadingIndicator>
        </div>
    );


};

export default AdminUserDetail;