import { FunctionalComponent, h } from 'preact';
import { Link, route } from 'preact-router';
import { useEffect, useState } from 'preact/hooks';
import BackButton from '../../../components/back-button';
import DateDisplay from '../../../components/date-display';
import LoadingIndicator from '../../../components/loading-indicator';
import { APIService } from '../../../services/api.service';
import { FormatterService } from '../../../services/formatter.service';
import { LanguageService } from '../../../services/language.service';
import { SessionService } from '../../../services/session.service';
import style from './style.css';
import ShipmentDetailDownload from './detail-download';

const ShipmentDetail: FunctionalComponent<any> = ({ id, ...props }) => {
    const format = new FormatterService();
    const language = new LanguageService();
    const api = new APIService();

    const [details, setDetails] = useState<any>();
    const session = new SessionService();
    if (session.authenticated() === false) {
        route('/');
    }


    const [loading, setLoading] = useState(true);
    const [initialLoad, setInitialLoad] = useState(false);
    useEffect(() => {
        api.shipmentDetail(id).then(result => {
            setDetails(result);
            setLoading(false);
        });
    }, [initialLoad]);

    const shipTo = () => {
        return (
            !details ? <p>Loading</p> :
                <div>
                    <p class={style.shipLine}>{details.Header.CustomerName}</p>
                    <p class={style.shipLine} style="margin-top:-10px">{details.Header.ShipToAddressLine1}</p>
                    <p class={style.shipLine} style="margin-top:-10px">{details.Header.ShipToAddressLine2}</p>
                    <p class={style.shipLine} style="margin-top:-10px">{details.Header.ShipToAddressLine3}</p>
                </div>
        );
    }

    const headerTable = () => {
        return (
            !details ? <p>Loading</p> :
                <table>
                    <thead>
                        <tr>
                            <th>{language.translate('Invoice #')}</th>
                            <th>{language.translate('Amount')}</th>
                            <th>{language.translate('Route')}</th>
                            <th>{language.translate('Ship Via')}</th>
                            <th>{language.translate('AWB/Manif.')}</th>
                            <th>{language.translate('Total Lines')}</th>
                            <th>{language.translate('Ship Date')}</th>
                            <th>{language.translate('Arrival Date')}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>{details.Header.InvoiceNumber}</td>
                            <td>$ <span style="float: right;">{format.currency(details.Header.InvoiceAmount)}</span></td>
                            <td>{details.Header.Route}</td>
                            <td>{details.Header.ShipVia}</td>
                            <td>{details.Header.AirWaybill}</td>
                            <td>{details.Header.TotalLines}</td>
                            <td><DateDisplay date={details.Header.ShipDate} /></td>
                            <td><DateDisplay date={details.Header.ArrivalDate} /></td>
                        </tr>
                    </tbody>
                </table>)
    }

    const linesTable = () => {
        return (
            !details ? <p>Loading</p> :
                <table>
                    <thead>
                        <tr>
                            <th>{language.translate('Customer Order')}</th>
                            <th>{language.translate('Line')}</th>
                            <th>{language.translate('Part Number')}</th>
                            <th>{language.translate('Description')}</th>
                            <th>{language.translate('Case')}</th>
                            <th>{language.translate('Qty. Shipped')}</th>
                            <th>{language.translate('Price')}</th>
                            <th>{language.translate('Ext. Price')}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {details.Lines.map((item: any) =>
                            <tr key={item.CustomerOrderNumber + ' - ' + item.CustomerOrderLine}>
                                <td><Link href={"/order/search/" + encodeURIComponent(item.CustomerOrderNumber)}>{item.CustomerOrderNumber}</Link></td>
                                <td>{item.CustomerOrderLine}</td>
                                <td>{item.PartNumber}</td>
                                <td>{item.Description}</td>
                                <td>{item.CaseNumber}</td>
                                <td>{item.QTYShipped}</td>
                                <td>$ <span style="float: right;">{format.currency(item.Price)}</span></td>
                                <td>$ <span style="float: right;">{format.currency(item.ExtendedPrice)}</span></td>                             
                            </tr>)
                        }
                    </tbody>
                </table>)
    }

    return (
        <div>
            <h1><BackButton />{language.translate('Invoice #') + id} <ShipmentDetailDownload id={id} /></h1>
            <LoadingIndicator loading={loading}>
                <div style="display: flex">
                    <div style="margin-right:12px"><p class={style.shipLine}>{language.translate('Ship To:')}</p></div>
                    {shipTo()}
                </div>
                <p class={style.shipLine}>{language.translate('Shipment Header:')}</p>
                {headerTable()}
                <p class={style.shipLine}>{language.translate('Shipment Detail:')}</p>
                {linesTable()}
            </LoadingIndicator>
        </div>
    );


};

export default ShipmentDetail;