import { FunctionalComponent, h } from 'preact';
import style from './style.css';
import { APIService } from '../../../services/api.service';
import { LanguageService } from '../../../services/language.service';
import { useEffect, useState } from 'preact/hooks';
import Button, { ButtonColor } from '../../../components/button';
import { OrderUploadResponse } from '../../../models';
import { route as Router } from 'preact-router';

const RegularOrder: FunctionalComponent = () => {
    const api = new APIService();
    const language = new LanguageService();

    const [loading, setLoading] = useState(false);
    const [route, setRoute] = useState('');
    const [orderType, setOrderType] = useState('Regular Stock');
    const [notes, setNotes] = useState<string>('');
    const [sourceCode, setSourceCode] = useState('');
    const [selectedFile, setSelectedFile] = useState<File | null>(null);
    const [response, setResponse] = useState<OrderUploadResponse | null>(null);
    const [sourceCodeOptions, setSourceCodeOptions] = useState<any[]>([]);

    // Only changes with initialLoad, so after initial load we won't load data again.
    const [initialLoad, setInitialLoad] = useState(false);
    useEffect(() => {
        api.lookupSourceCodes().then(results => setSourceCodeOptions(results))
    }, [initialLoad]);

    useEffect(() => {
        // Make sure order type can't be in invalid state.
        if (route === 'AIR' && orderType === 'Regular Stock') {
            setOrderType('Regular AIR');
        } else if (route != 'AIR' && orderType === 'Regular AIR') {
            setOrderType('Regular Stock')
        }
    }, [route])

    const formValid = () => {
        if (route === '') {
            return false; // Route is required
        }
        if (orderType === '') {
            return false; // Order type is required
        }
        if (selectedFile === null) {
            return false; // Selected file is required
        }
        if (orderType === 'Created Demand' && sourceCode === '') {
            return false; // For created demand, source code is required
        }
        return true;
    }

    const formAsParams = () => {
        return {
            route: route,
            orderType: orderType,
            sourceCode: sourceCode,
            notes: notes,
        }
    }

    const orderTypeChange = (v: any) => {
        setOrderType(v.target.value);
    }

    const sourceCodeChange = (v: any) => {
        setSourceCode(v.target.value);
    }

    const notesChange = (v: any) => {
        setNotes(v.target.value);
    }

    const fileChange = (v: any) => {
        if (v.target.files.length === 0) {
            setSelectedFile(null);
            return
        }
        setSelectedFile(v.target.files[0]);
    }

    const submitOrder = () => {
        // This is defensive, but make sure form is valid.
        if (!formValid()) { return }
        setLoading(true);
        api.orderUpload(selectedFile!, formAsParams()).then(resp => {
            setResponse(resp); setLoading(false);
            if (resp.success === true) {
                Router('/new-order/submitted');
            }
        });
    }

    const resetOrder = () => {
        setRoute('');
        setNotes('');
        setSourceCode('');
        setSelectedFile(null);
    }

    const routeCheckbox = (id: string, label: string) => {
        return (<div>
            <label class={style.container}>{language.translate(label)}
                <input type="radio" name="route" id={id} checked={route === id} onClick={() => setRoute(id)} />
                <span class={style.checkmark}></span>
            </label>
        </div>);
    }

    const fileDisplay = () => {
        return (<div>
            <label class={style.fileUpload}>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" class={style.uploadIcon}>
                    <path fill="#39AB2F" d="M117.7 314.3c-3.125-3.125-8.188-3.125-11.31 0s-3.125 8.188 0 11.31l64 64C171.8 391.2 173.9 392 175.1 392h.4375c2.281-.125 4.391-1.219 5.812-3l96-120c2.75-3.438 2.203-8.5-1.25-11.25C273.6 255 268.5 255.6 265.7 259l-90.41 113L117.7 314.3zM374.6 150.6l-141.3-141.3C227.4 3.371 219.2 0 210.7 0H64C28.65 0 0 28.65 0 64l.0065 384c0 35.34 28.65 64 64 64H320c35.35 0 64-28.66 64-64V173.3C384 164.8 380.6 156.6 374.6 150.6zM224 22.63L361.4 160H248C234.8 160 224 149.2 224 136V22.63zM368 448c0 26.47-21.53 48-48 48H64c-26.47 0-48-21.53-48-48V64c0-26.47 21.53-48 48-48h144v120c0 22.06 17.94 40 40 40h120V448z" />
                </svg>
                <span class={style.text} style="top:22px; flex:1">{selectedFile?.name}</span>
                <svg xmlns="http://www.w3.org/2000/svg" height="40" viewBox="0 96 960 960" width="40" style="position:relative; top: 10px;" onClick={() => setTimeout(() => setSelectedFile(null), 50)}>
                    <path d="m251.333 851.333-46.666-46.666L433.334 576 204.667 347.333l46.666-46.666L480 529.334l228.667-228.667 46.666 46.666L526.666 576l228.667 228.667-46.666 46.666L480 622.666 251.333 851.333Z" />
                </svg>
            </label>
        </div>);
    }

    const fileSelector = () => {
        if (selectedFile != null) {
            return fileDisplay();
        }
        return (<div>
            <label class={style.fileUpload}>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" class={style.uploadIcon}><path d="M374.6 150.6l-141.3-141.3C227.4 3.371 219.2 0 210.7 0H64C28.65 0 0 28.65 0 64l.0065 384c0 35.34 28.65 64 64 64H320c35.35 0 64-28.66 64-64V173.3C384 164.8 380.6 156.6 374.6 150.6zM224 22.63L361.4 160H248C234.8 160 224 149.2 224 136V22.63zM368 448c0 26.47-21.53 48-48 48H64c-26.47 0-48-21.53-48-48V64c0-26.47 21.53-48 48-48h144v120c0 22.06 17.94 40 40 40h120V448zM64 256v160c0 17.67 14.33 32 32 32h192c17.67 0 32-14.33 32-32V256c0-17.67-14.33-32-32-32H96C78.33 224 64 238.3 64 256zM120 432H96c-8.822 0-16-7.176-16-16v-32h40V432zM120 368H80V320h40V368zM184 432h-48V384h48V432zM184 368h-48V320h48V368zM248 432h-48V384h48V432zM248 368h-48V320h48V368zM304 416c0 8.824-7.178 16-16 16h-24V384h40V416zM304 368h-40V320h40V368zM304 256v48h-224V256c0-8.824 7.178-16 16-16h192C296.8 240 304 247.2 304 256z" /></svg>
                <input type="file" id="fileSelect" name="filename" onChange={fileChange} accept=".csv,.txt" />
                <span class={style.text}>{language.translate('Click to Select Order Details')} <span class={style.required}>*</span>
                    <br />
                    <span class={style.secondLine}>{language.translate('Supports txt and csv')}</span></span>
            </label>
        </div>);
    }

    return (
        <div style="width: 100%; max-width: 600px;">
            <h1>{language.translate('Regular Order')}</h1>
            <div style="display: flex; flex-wrap: wrap; margin-top: 8px;">
                <label class={style.label}>{language.translate('Route')}: <span class={style.required}>*</span></label>
                {routeCheckbox("SEA", "SEA")}
                {routeCheckbox("SAIR", "SAIR")}
                {routeCheckbox("TRK", "TRK")}
                {routeCheckbox("AIR", "AIR")}
            </div>
            <div>
                <label for="orderType" class={style.label}>{language.translate('Order Type')}: <span class={style.required}>*</span></label>
                <select id="orderType" onChange={orderTypeChange} value={orderType}>
                    {
                        route === 'AIR' ?
                            <option value="Regular AIR">{language.translate('Regular AIR')}</option> :
                            <option value="Regular Stock">{language.translate('Regular Stock')}</option>
                    }
                    <option value="Created Demand">{language.translate('Created Demand')}</option>
                </select>
            </div>
            {orderType === 'Created Demand' &&
                <div>
                    <label for="sourceCode" class={style.label}>{language.translate('Source Code')}: <span class={style.required}>*</span></label>
                    <select id="sourceCode" onChange={sourceCodeChange} value={sourceCode}>
                        {sourceCodeOptions.map(code => <option value={code.Code}>{code.Code} - {language.isSpanish() ? code.DescriptionES : code.DescriptionEN}</option>)}
                    </select>
                </div>}
            {fileSelector()}
            <div style="display: flex;">
                <label class={style.label}>{language.translate('Notes')}:</label>
                <input type="text" style="margin-left:8px" value={notes} onChange={notesChange} />
            </div>
            {loading && <progress></progress>}
            {response && response.success == false &&
                <div>
                    <p>{language.translate('Please fix the following errors')}:</p>
                    {response.errorList.map(error =>
                        <p key={error} class={style.error}>{error}</p>)}
                </div>}
            <div style="float:right; margin-top:18px; margin-bottom:12px; clear:both;">
                <Button style="margin-right:8px" text="Reset" color={ButtonColor.Secondary} onClick={resetOrder} />
                <Button text="Submit Order" disabled={!formValid()} onClick={submitOrder} />
            </div>
        </div>
    );
};



export default RegularOrder;
