export class OrderSearchParams {
    OrderNum = '';
    DateTo:Date | undefined;
    DateFrom: Date | undefined;
    IncludeClosed = true;
    Routes: string[] = [];

    constructor() {
        this.DateTo = new Date();
        this.DateFrom = new Date();
        this.DateFrom.setMonth(this.DateFrom.getMonth() - 1);
    }
}