import { FunctionalComponent, h } from 'preact';
import { Link, route } from 'preact-router';
import { useEffect, useState } from 'preact/hooks';
import { AdminService } from '../../../services/admin.service';
import { LanguageService } from '../../../services/language.service';
import { SessionService } from '../../../services/session.service';
import CustomerDetail from '../../../components/customer-detail/customer-detail';
import { Customer } from '../../../models';

const AdminCustomers: FunctionalComponent = () => {
    const admin = new AdminService();
    const session = new SessionService();
    if (session.authenticated() === false) {
        route('/');
    }
    const language = new LanguageService();

    const [newCustomer, setNewCustomer] = useState(new Customer());

    const [results, setResults] = useState<any[]>([]);
    const [loading, setLoading] = useState(true);

    const [triggerLoad, setTriggerLoad] = useState(false);
    useEffect(() => {
        admin.customerList().then(results => {
            setResults(results);
            setLoading(false);
        })
    }, [triggerLoad]);

    const createCustomer = (newCust: Customer) => {
        if (newCust.ID < 1 || newCust.Name == '') {
            return;
        }
        setLoading(true);
        admin.customerCreate(newCust).then(result => {
            setNewCustomer(new Customer());
            setTriggerLoad(!triggerLoad);
        }).catch(() => alert('Customer ID exists'));
    }

    return (
        <div>
            <h1>{language.translate('Customers')}</h1>
            {loading && <progress></progress>}
            <h2>Create Customer</h2>
            <CustomerDetail allowEditID={true} labelText='Create Customer' customer={newCustomer} onSave={createCustomer} />

            {results.length > 0 &&
                <table style="margin-top: 10px; width:auto;">
                    <thead>
                        <tr>
                            <th style="padding-right:18px;">{language.translate('ID')}</th>
                            <th style="padding-right:18px;">{language.translate('Name')}</th>
                            <th style="padding-right:18px;">{language.translate('# Users')}</th>
                            <th style="padding-right:18px;">{language.translate('Allow Orders?')}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {results.map(item =>
                            <tr key={item.ID}>
                                <td>{item.ID}</td>
                                <td><Link href={`/admin/customers/${item.ID}`}>{item.Name}</Link></td>
                                <td>{item.UserCount}</td>
                                <td>{language.translate(item.AllowOrders ? 'Yes' : 'No')}</td>
                            </tr>)
                        }
                    </tbody>
                </table>
            }
        </div>
    );
};

export default AdminCustomers;
