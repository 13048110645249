export class SourceCode {
    ID: number = 0;
    Code: string = '';
    DescriptionEN: string = '';
    DescriptionES: string = '';

    constructor() {

    }

    setValue(id: string, value: any) {
        switch (id) {
            case 'Code':
                this.Code = value;
                break;
            case 'DescriptionEN':
                this.DescriptionEN = value;
                break;
            case 'DescriptionES':
                this.DescriptionES = value;
                break;
        }
    }

    valid(): boolean {
        console.log(this);
        return this.Code != '' 
        && this.DescriptionEN != ''
        && this.DescriptionES != '';
    }
}