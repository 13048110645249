import { Fragment, FunctionalComponent, h } from 'preact';
import { LanguageService } from '../../services/language.service';
import { Customer } from '../../models';
import style from './style.css';

export class customerProps {
    customer: Customer = new Customer();

    allowEditID = false;

    /**
     * Text for the save button. Defaults to 'Create Customer'.
     */
    labelText: string = 'Create Customer';

    /**
     * What happens when the save/update button is clicked.
     */
    onSave?: (updatedCustomer: Customer) => void;
}


const CustomerDetail: FunctionalComponent<customerProps> = (props) => {
    const ls = new LanguageService();
    const updateCustomerName = (newValue: string) => {
        props.customer.Name = newValue;
    }

    const updateCustomerID = (newValue: string) => {
        props.customer.ID = +newValue;
    }

    const toggleCustomerAllowOrders = () => {
        props.customer.AllowOrders = !props.customer.AllowOrders;
    }

    const handleSave = () => {
        if (props.onSave) {
            props.onSave(props.customer);
        }
    }

    return (
        <Fragment>
            <div class={style.inputWrapper} style="width: 140px;">
                <label for="customerNo">{ls.translate('Customer Number')}:</label>
                <input type="text" name="customerNo" id="customerNo" value={props.customer.ID} onChange={(e: any) => updateCustomerID(e.target.value)} disabled={!props.allowEditID}></input>
            </div>
            <div class={style.inputWrapper}>
                <label for="customerName">{ls.translate('Customer Name')}:</label>
                <input type="text" name="customerName" id="customerName" value={props.customer.Name} onChange={(e: any) => updateCustomerName(e.target.value)}></input>
            </div>
            <div class={style.inputWrapper}>
                <label for="allowOrders">{ls.translate('Allow Orders')}?</label>
                <input style="position: relative; top: 6px; cursor: pointer;" type="checkbox" name="allowOrders" id="allowOrders" checked={props.customer.AllowOrders} onChange={(e: any) => toggleCustomerAllowOrders()}></input>
            </div>
            <p style="clear:both; padding-top:10px; margin-bottom: 12px;"><button onClick={handleSave}>{ls.translate(props.labelText)}</button></p>
        </Fragment>
    );
};

export default CustomerDetail;
