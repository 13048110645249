import { FunctionalComponent, h } from 'preact';
import { useState } from 'preact/hooks';
import { LanguageService } from '../../services/language.service';
import style from './style.css';

export enum Icon {
    Search = 0,
    Reset,
}

export enum ButtonColor {
    Primary = 0,
    Secondary
}

export class buttonProps {
    /**
     * The text for the expansion button. We will use the translator service to translate it for you so no need.
     */
    text: string = '';
}


const Accordion: FunctionalComponent<buttonProps> = (props) => {
    const ls = new LanguageService();
    const [show, _setShow] = useState(false);
    // So we don't show animation on load
    const [animate, setAnimate] = useState(false);

    // Wrapper for useState for setShow.
    const setShow = (val: boolean) => {
        _setShow(val);
        // Once the user has interacted with the button, show animations.
        setAnimate(true);
    }
    return (
        <p style="margin-top:4px;">
            <span style="cursor: pointer; user-select: none;" onClick={() => setShow(!show)}>
                {ls.translate(props.text)}
                <svg xmlns="http://www.w3.org/2000/svg" height="24" width="24" style="position: relative; top: 7px;" fill="#be1e2d" class={style.icon + ' ' + (animate ? (show ? style.rotate : style.noRotate) : '')}>
                    <path d="m12 15.375-6-6 1.4-1.4 4.6 4.6 4.6-4.6 1.4 1.4Z" />
                </svg>
            </span>
            <div class={style.content + ' ' + (show ? style.shown : style.hidden)}>
                {props.children}
            </div>
        </p>
    );
};

export default Accordion;
