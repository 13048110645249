import { FunctionalComponent, h } from 'preact';
import { route } from 'preact-router';
import { useEffect, useState } from 'preact/hooks';
import KitListModal from '../../../components/kit-list-modal';
import SearchBar from '../../../components/search-bar';
import SupersessionListModal from '../../../components/supersession-list-modal';
import { APIService } from '../../../services/api.service';
import { FormatterService } from '../../../services/formatter.service';
import { LanguageService } from '../../../services/language.service';
import { SessionService } from '../../../services/session.service';
import style from './style.css';

const ProductSearch: FunctionalComponent = () => {
    const format = new FormatterService();
    const api = new APIService();
    const session = new SessionService();
    if (session.authenticated() === false) {
        route('/');
    }
    const language = new LanguageService();

    const initialPrompt = language.translate('Enter product number(s) and press enter to search.');
    const noResults = language.translate('No products found.');
    const [searchInput, setSearchInput] = useState(session.getProductSearch());
    const [resultMessage, setResultMessage] = useState(initialPrompt);
    const [results, setResults] = useState<any[]>([]);
    const [loading, setLoading] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const [modalLoading, setModalLoading] = useState(false);
    const [modalItems, setModalItems] = useState([]);

    const [kitListOpen, setKitListOpen] = useState(false);
    const [kitListLoading, setKitListLoading] = useState(false);
    const [kitListItems, setKitListItems] = useState([]);


    const closeModal = () => {
        setModalOpen(!modalOpen);
    }

    const showModal = (item: any) => {
        setModalLoading(true);
        setModalOpen(true);
        api.supersessionList(item.PartNumber).then(result => {
            setModalItems(result);
            setModalLoading(false);
        });
    }

    const closeKitList = () => {
        setKitListOpen(!kitListOpen);
    }

    const showKitList = (item: any) => {
        setKitListLoading(true);
        setKitListOpen(true);
        api.kitList(item.PartNumber).then(result => {
            setKitListItems(result);
            setKitListLoading(false);
        });
    }

    const formSubmit = (event: any) => {
        console.log('prevent')
        event.preventDefault();
        doSearch(searchInput);
    }

    const formReset = (event: any) => {
        doSearch('');
    }

    const inputChange = (event: any) => {
        let value = event.target.value;
        setSearchInput(value);// = event.target.value;
        if (event instanceof InputEvent) {
            // If the user pasted, do search immediately.
            if (event.inputType === 'insertFromPaste') {
                doSearch(value);
            }
        } else {
            // This happens when user clears search screen using the X
            setResults([]);
            setResultMessage(initialPrompt);
        }
    }

    const doSearch = (query: string) => {
        session.setProductSearch(query);
        if (query === '') {
            setResults([]);
            setResultMessage(initialPrompt);
        } else {
            setLoading(true);
            api.searchProducts(query).then(results => {
                setLoading(false);
                setResults(results);
                setResultMessage(results.length === 0 ? noResults : '');
            })
        }
    }

    const replaceSuperseded = (event: Event, old: string, superseding: string) => {
        event.preventDefault();
        let newSearchString = searchInput.replace(old, superseding);
        setSearchInput(newSearchString);
        doSearch(newSearchString);
    }

    // This is a bit hack-y but creating a hook for initial load which is then never updated 
    // so we can do our searh on initial load if there is any stored search in the session
    // service.
    const [initialLoad, setInitialLoad] = useState(false);
    useEffect(() => {
        if (searchInput != '') {
            doSearch(searchInput);
        }
    }, [initialLoad]);

    return (
        <div class={style.search}>
            <SupersessionListModal isOpen={modalOpen} loading={modalLoading} items={modalItems} closeModal={closeModal} />
            <KitListModal isOpen={kitListOpen} loading={kitListLoading} items={kitListItems} closeModal={closeKitList} />
            <h1>{language.translate('Product Search')}</h1>
            <form onSubmit={formSubmit} onReset={formReset}>
                <SearchBar label="Enter part numbers, separated by a space or comma" value={searchInput} onChange={(newValue) => setSearchInput(newValue)} onPaste={(value) => doSearch(value)} />
            </form>
            {resultMessage.length > 0 && <p>{resultMessage}</p>}
            {loading && <progress></progress>}
            {results.length > 0 &&
                <table style="margin-top: 10px">
                    <thead>
                        <tr>
                            <th>{language.translate('Part Number')}</th>
                            <th>{language.translate('Description')}</th>
                            <th>{language.translate('Price')}</th>
                            <th>{language.translate('Weight (KG)')}</th>
                            <th>{language.translate('Dim. (L)')}</th>
                            <th>{language.translate('Dim. (W)')}</th>
                            <th>{language.translate('Dim. (H)')}</th>
                            <th>{language.translate('Prd. Cde.')}</th>
                            <th>{language.translate('Superseded By')}</th>
                            <th>{language.translate('HMM Inv.')}</th>
                            <th>{language.translate('HML Inv.')}</th>
                            <th>{language.translate('China Inv.')}</th>
                            <th>{language.translate('Available Intransit Qty')}</th>
                            <th>{language.translate('Supersession List')}</th>
                            <th>{language.translate('Note')}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {results.map(item =>
                            <tr key={item.PartNumber}>
                                <td>{item.PartNumber}</td>
                                <td>{language.isSpanish() ? item.ItemDescriptionES : item.ItemDescriptionEN}</td>
                                <td>$ <span style="float: right;">{format.currency(item.ListPrice)}</span></td>
                                <td>{format.weight(item.WeightKG)}</td>
                                <td>{item.Length}</td>
                                <td>{item.Width}</td>
                                <td>{item.Height}</td>
                                <td>{item.ProductCode}</td>
                                <td><a title={language.translate('Replace part with superseding')} onClick={(e) => replaceSuperseded(e, item.PartNumber, item.SupersedingPartNumber)}>{item.SupersedingPartNumber}</a></td>
                                <td>{format.int(item.HMMInventory)}</td>
                                <td>{format.int(item.HMLInventory)}</td>
                                <td>{format.int(item.ChinaInventory)}</td>
                                <td>{format.int(item.AvailableIntransitQty)}</td>
                                <td>{item.SupersessionListFlag === 'Y' ? <a onClick={() => showModal(item)}>{language.translate('Yes')}</a> : ''}</td>
                                <td>{language.isSpanish() ? item.NoteES : item.NoteEN}
                                    {item.KitParent != '' ? <a onClick={() => showKitList(item)}>{language.translate('Kit')}</a> : ''}</td>
                            </tr>)
                        }
                    </tbody>
                </table>
            }
        </div>
    );
};

export default ProductSearch;
