import { FunctionalComponent, h } from 'preact';
import { Link } from 'preact-router/match';
import style from './style.css';
import { APIService } from '../../../services/api.service';
import { AdminService } from '../../../services/admin.service';
import { useEffect, useState } from 'preact/hooks';
import { LanguageService } from '../../../services/language.service';
import { SourceCode } from '../../../models';
import Confirm from '../../../components/confirm/confirm';

const AdminLookups: FunctionalComponent = () => {
    const api = new APIService();
    const admin = new AdminService();
    const language = new LanguageService();

    const [newRecord, setNewRecord] = useState(new SourceCode());
    const [valid, setValid] = useState(false);

    const [results, setResults] = useState<any[]>([]);
    const [loading, setLoading] = useState(true);

    const [sourceToDelete, setSourceToDelete] = useState<SourceCode | null>(null);

    // Only changes with initialLoad, so after initial load we won't load data again.
    const [initialLoad, setInitialLoad] = useState(false);
    useEffect(() => {
        api.lookupSourceCodes().then(results => {
            setResults(results);
            setLoading(false);
        })
    }, [initialLoad]);

    const formInput = (event: any) => {
        const value = event.target.value;
        const name: string = event.target.name;
        let record = newRecord;
        record.setValue(name, value);
        setNewRecord(record);
        setValid(record.valid());
    }

    const addNewCode = () => {
        setLoading(true);
        admin.sourceCodeCreate(newRecord).then(result => setInitialLoad(!initialLoad));
    }

    const handleDeleteClose = (result: boolean) => {
        const src = sourceToDelete;
        // Nothing to do.
        if (result === false || src == null) {
            setSourceToDelete(null);
            return;
        }
        setLoading(true);
        admin.sourceCodeDelete(src.ID).then(() => {
            setSourceToDelete(null);
            setInitialLoad(!initialLoad);
        });
    }

    return (
        <div>
            <Confirm title="Delete Source Code?" shown={sourceToDelete != null} onClose={handleDeleteClose} positiveAction='Delete' negativeAction='Cancel'>
                <p>This will delete the source code from the database. This will not affect existing orders.</p>
            </Confirm>
            <h1>Lookups</h1>
            <h2>Source Code</h2>
            <div style="display:inline-block;">
                <div class={style.inputWrapper}>
                    <label for="Code">Code:</label>
                    <input type="text" name="Code" id="Code" value={newRecord.Code} onInput={formInput} maxLength={3}></input>
                </div>
                <div class={style.inputWrapper}>
                    <label for="DescriptionEN">Description (EN):</label>
                    <input type="text" name="DescriptionEN" id="DescriptionEN" value={newRecord.DescriptionEN} onInput={formInput}></input>
                </div>
                <div class={style.inputWrapper}>
                    <label for="DescriptionES">Description (ES)</label>
                    <input type="text" name="DescriptionES" id="DescriptionES" value={newRecord.DescriptionES} onInput={formInput}></input>
                </div>
                <p style="clear:both; padding-top:10px; margin-bottom: 12px;"><button disabled={!valid} onClick={addNewCode}>Add Source Code</button></p>
            </div>
            {loading && <progress></progress>}
            {results.length > 0 &&
                <table style="margin-top: 10px; width:auto;">
                    <thead>
                        <tr>
                            <th style="padding-right:18px;">{language.translate('Code')}</th>
                            <th style="padding-right:18px;">{language.translate('Description (EN)')}</th>
                            <th style="padding-right:18px;">{language.translate('Description (ES)')}</th>
                            <th style="padding-right:18px;"></th>
                        </tr>
                    </thead>
                    <tbody>
                        {results.map(item =>
                            <tr key={item.ID}>
                                <td>{item.Code}</td>
                                <td>{item.DescriptionEN}</td>
                                <td>{item.DescriptionES}</td>
                                <td><Link onClick={() => setSourceToDelete(item)}><svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M4.5 12.5C4.5 12.7812 4.25 13 4 13C3.71875 13 3.5 12.7812 3.5 12.5V5.5C3.5 5.25 3.71875 5 4 5C4.25 5 4.5 5.25 4.5 5.5V12.5ZM7.5 12.5C7.5 12.7812 7.25 13 7 13C6.71875 13 6.5 12.7812 6.5 12.5V5.5C6.5 5.25 6.71875 5 7 5C7.25 5 7.5 5.25 7.5 5.5V12.5ZM10.5 12.5C10.5 12.7812 10.25 13 10 13C9.71875 13 9.5 12.7812 9.5 12.5V5.5C9.5 5.25 9.71875 5 10 5C10.25 5 10.5 5.25 10.5 5.5V12.5ZM9.6875 0.71875L10.5 2H13.5C13.75 2 14 2.25 14 2.5C14 2.78125 13.75 3 13.5 3H13V13.5C13 14.9062 11.875 16 10.5 16H3.5C2.09375 16 1 14.9062 1 13.5V3H0.5C0.21875 3 0 2.78125 0 2.5C0 2.25 0.21875 2 0.5 2H3.46875L4.28125 0.71875C4.53125 0.28125 5.03125 0 5.53125 0H8.4375C8.9375 0 9.4375 0.28125 9.6875 0.71875ZM4.625 2H9.34375L8.84375 1.25C8.75 1.09375 8.59375 1 8.4375 1H5.53125C5.375 1 5.21875 1.09375 5.125 1.25L4.625 2ZM2 13.5C2 14.3438 2.65625 15 3.5 15H10.5C11.3125 15 12 14.3438 12 13.5V3H2V13.5Z" fill="#BE1E2D" />
                                </svg>
                                </Link></td>
                            </tr>)
                        }
                    </tbody>
                </table>
            }
        </div>
    );
};

export default AdminLookups;
