import { FunctionalComponent, h } from 'preact';
import { Link, route } from 'preact-router';
import { useEffect, useState } from 'preact/hooks';
import { AdminService } from '../../../../services/admin.service';
import { LanguageService } from '../../../../services/language.service';
import { SessionService } from '../../../../services/session.service';
import AdminUserAdd from './add';

const AdminUserList: FunctionalComponent = () => {
    const admin = new AdminService();
    const session = new SessionService();
    if (session.authenticated() === false) {
        route('/');
    }
    const language = new LanguageService();

    const [results, setResults] = useState<any[]>([]);
    const [loading, setLoading] = useState(true);

    // Only changes with initialLoad, so after initial load we won't load data again.
    const [initialLoad, setInitialLoad] = useState(false);
    useEffect(() => {
        admin.userList().then(results => {
            setResults(results);
            setLoading(false);
        })
    }, [initialLoad]);

    return (
        <div>
            <h1>{language.translate('Users')}</h1>
            <AdminUserAdd></AdminUserAdd>
            {loading && <progress></progress>}
            {results.length > 0 &&
                <table style="margin-top: 10px; width:auto;">
                    <thead>
                        <tr>
                        <th style="padding-right:18px;">{language.translate('Name')}</th>
                        <th style="padding-right:18px;">{language.translate('Email')}</th>
                        <th style="padding-right:18px;">{language.translate('Customer #')}</th>
                        <th style="padding-right:18px;">{language.translate('Customer Name')}</th>
                        <th style="padding-right:18px;">{language.translate('Allow Orders?')}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {results.map(item =>
                            <tr key={item.ID}>
                                <td><Link href={`/admin/users/${item.ID}`}>{item.Name}</Link></td>
                                <td>{item.Email}</td>
                                <td>{item.CustomerID}</td>
                                {item.CustomerName === 'Invalid Customer ID' ? 
                                    <td>{item.CustomerName}</td> : 
                                    <td><Link href={`/admin/customers/${item.CustomerID}`}>{item.CustomerName}</Link></td>
                                }
                                <td>{item.AllowOrders ? 'Yes' : 'No'}</td>
                            </tr>)
                        }
                    </tbody>
                </table>
            }
        </div>
    );
};

export default AdminUserList;
