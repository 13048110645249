import { FunctionalComponent, h } from 'preact';
import { useState } from 'preact/hooks';
import { LanguageService } from '../../services/language.service';
import Button, { Icon, ButtonColor } from '../button';
import style from './style.css';

export class searchBarProps {
    /**
     * The text for the search bar label. We will use the translator service to translate it for you so no need.
     */
    label: string = '';

    value: string = '';

    /**
     * What happens when the button is clicked.
     */
    onClear?: () => void;

    onChange?: (newValue: string) => void;

    onPaste?: (newValue: string) => void;

    /**
     * Called when user clicks "download" button. Must return promise so we can set the "disabled" state on 
     * the download button while the report is being generated.
     */
    onDownload?: () => Promise<void>;
}

const SearchBar: FunctionalComponent<searchBarProps> = (props) => {
    const language = new LanguageService();
    const [downloadPending, setDownloadPending] = useState(false);
    const inputChange = (event: any) => {
        let value = event.target.value;
        if (props.onChange) {
            props.onChange(value);
        }
        // If the user pasted, let parents know.
        if (event.inputType === 'insertFromPaste' && props.onPaste) {
            props.onPaste(value);
        }
    }
    const download = () => {
        if (props.onDownload) {
            setDownloadPending(true)
            props.onDownload().then(() => setDownloadPending(false));
        }
    }
    return (
        <div>
            <p>{language.translate(props.label)}</p>
            <div style="display:flex">
                <input type="text" value={props.value} autoFocus={true} onInput={inputChange} id="search" name="search" />
                <div class={style.searchButtons}>
                    <Button text="Search" icon={Icon.Search} />
                    {/* If we have an onDownload implementation, show the download button. */}
                    { props.onDownload != null && <Button type="button" text="Download" icon={Icon.Download} onClick={download} color={ButtonColor.Accent} disabled={downloadPending} /> }
                    <Button type="reset" text="Reset" color={ButtonColor.Secondary} />
                </div>
            </div>
        </div>
    );
};


export default SearchBar;
