import { FunctionalComponent, h } from 'preact';
import { LanguageService } from '../../services/language.service';
import style from './style.css';

export enum Icon {
    Search = 0,
    Reset,
    Download,
}

export enum ButtonColor {
    Primary = 0,
    Secondary,
    Accent
}

export class buttonProps {
    /**
     * The text for the button. We will use the translator service to translate it for you so no need.
     */
    text: string = '';

    /**
     * The button icon. Leave undefined for no icon.
     */
    icon?: Icon;

    /**
     * This should be a valid button type:
     * - **submit:** The button submits the form data to the server. This is the default if the attribute is not specified for buttons associated with a <form>, or if the attribute is an empty or invalid value.
     * - **reset:** The button resets all the controls to their initial values, like <input type="reset">. (This behavior tends to annoy users.)
     * - **button:** The button has no default behavior, and does nothing when pressed by default. It can have client-side scripts listen to the element's events, which are triggered when the events occur.
     * 
     * See also: https://developer.mozilla.org/en-US/docs/Web/HTML/Element/button#attr-type
     */
    type?: string;

    /**
     * Button color will control foreground/background color of the button.
     */
    color?: ButtonColor;

    /**
     * HTML Style attribute.
     */
    style?: string;

    /**
     * Whether the button is diabled.
     */
    disabled?: boolean;


    /**
     * Pass through to html download attribute.
     */
    download?: any

    /**
     * What happens when the button is clicked.
     */
    onClick?: () => void;
}


const Button: FunctionalComponent<buttonProps> = (props) => {
    const ls = new LanguageService();
    return (
        <button type={props.type} onClick={props.onClick} disabled={props.disabled} style={props.style} class={getClass(props.color)} download={props.download}>
            <span style="display: inline-block; width: max-content;">
                {ls.translate(props.text)}
                {// If we have an icon, display it.
                    props.icon != null && icon(props.icon)}
            </span>
        </button >
    );
};

const getClass = (color?: ButtonColor) => {
    switch (color) {
        case ButtonColor.Secondary:
            return style.secondary;
        case ButtonColor.Accent:
            return style.accent;
        default:
            return style.primary;
    }
}

// OK the idea here is: we could have different icons. For now we only have one.
const icon = (icon: Icon) => {
    switch (icon) {
        case Icon.Download:
            return (<svg viewBox="0 96 960 960" style="margin-left: 6px; width:25px; height:25px; float: right;">
                <path fill="white" xmlns="http://www.w3.org/2000/svg" d="M240 896q-33 0-56.5-23.5T160 816V696h80v120h480V696h80v120q0 33-23.5 56.5T720 896H240Zm240-160L280 536l56-58 104 104V256h80v326l104-104 56 58-200 200Z" />
            </svg>)
        case Icon.Search:
            return (<svg viewBox="0 0 24 24" style="margin-left: 6px; width:25px; height:25px; float: right;">
                <path fill="white" xmlns="http://www.w3.org/2000/svg" d="M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z" />
            </svg>)
    }

}

export default Button;
