import { AuthResponse, OrderSearchParams, ShipmentSearchParams } from "../models";

/**
 * A service to encapsulate session state.
 */
export class SessionService {
    // An attempt to make this a singleton.
    static instance?: SessionService = undefined;
    constructor() {
        if (SessionService.instance) {
            return SessionService.instance;
        }
        SessionService.instance = this;
    }

    // Keys for our session storage objects.
    private readonly _tokenKey = 'authorization-token';
    private readonly _emailKey = 'user-email';
    private readonly _userNameKey = 'user-name';
    private readonly _customerKey = 'user-customer';
    private readonly _customerIDKey = 'user-customer-id';
    private readonly _isAdminKey = 'is-admin';
    private readonly _allowOrdersKey = 'allow-orders';
    private readonly _productSearchKey = 'product-search';
    private readonly _orderSearchParamsKey = 'order-search-params';
    private readonly _shipmentSearchParamsKey = 'shipment-search-params';

    /**
     * Return the current users' authentication token.
     */
    getToken() {
        let token = sessionStorage.getItem(this._tokenKey);
        if (token === null) {
            token = '';
        }
        return token;
    }

    /**
     * Set the current users' authentication token.
     * @param token The token to store
     */
    setCurrentUser(user: AuthResponse) {
        sessionStorage.setItem(this._tokenKey, user.token);
        sessionStorage.setItem(this._emailKey, user.email);
        sessionStorage.setItem(this._userNameKey, user.userName);
        sessionStorage.setItem(this._customerKey, user.customer);
        sessionStorage.setItem(this._customerIDKey, user.customerID.toString());
        sessionStorage.setItem(this._isAdminKey, user.isAdmin ? 'Y' : 'N');
        sessionStorage.setItem(this._allowOrdersKey, user.allowOrders ? 'Y' : 'N');
        this.nextAuth(true);
    }

    logout() {
        sessionStorage.clear();
        this.nextAuth(false);
    }

    /**
     * Check to see if the user is authenticated.
     */
    authenticated(): boolean {
        let authenticated = sessionStorage.getItem(this._tokenKey) != null;
        return authenticated;
    }

    isAdmin(): boolean {
        return sessionStorage.getItem(this._isAdminKey) === 'Y';
    }

    /**
     * Whether this user can create/access orders.
     */
    showOrders(): boolean {
        const allowOrders = sessionStorage.getItem(this._allowOrdersKey);
        return allowOrders === 'Y';
    }

    userInfo() {
        return {
            email: sessionStorage.getItem(this._emailKey) ?? '',
            userName: sessionStorage.getItem(this._userNameKey) ?? '',
            customer: sessionStorage.getItem(this._customerKey) ?? '',
        }
    }

    setProductSearch(value: string) {
        sessionStorage.setItem(this._productSearchKey, value);
    }

    getProductSearch(): string {
        let val = sessionStorage.getItem(this._productSearchKey);
        return val ? val : '';
    }

    getOrderSearchParams(): OrderSearchParams {
        const storedParams = sessionStorage.getItem(this._orderSearchParamsKey);
        if (storedParams != null && storedParams != undefined) {
            let val = JSON.parse(storedParams);
            // Revive dates
            val.DateFrom = new Date(val.DateFrom);
            val.DateTo = new Date(val.DateTo);
            return val;
        }
        // Nothing stored, return new params.
        return new OrderSearchParams();
    }

    setOrderSearchParams(params: OrderSearchParams) {
        sessionStorage.setItem(this._orderSearchParamsKey, JSON.stringify(params));
    }

    getShipmentSearchParams(): ShipmentSearchParams {
        const storedParams = sessionStorage.getItem(this._shipmentSearchParamsKey);
        if (storedParams) {
            return JSON.parse(storedParams);
        }
        return new ShipmentSearchParams();
    }
    setShipmentSearchParams(params: ShipmentSearchParams) {
        sessionStorage.setItem(this._shipmentSearchParamsKey, JSON.stringify(params));
    }

    nextAuth(val: boolean) {
        this.watchAuth(val);
    }

    watchAuth(val: boolean) {

    }
}