/**
 * A service to encapsulate formatting tasks
 */
export class FormatterService {
    currencyFormat = Intl.NumberFormat(undefined, { minimumFractionDigits: 2 });
    weightFormat = Intl.NumberFormat(undefined, { minimumFractionDigits: 3 });
    intFormat = Intl.NumberFormat(undefined);

    constructor() { }

    getFormattedDate(date: Date): string {
        let parts = this.extractDateParts(date);
        // Return in date format such as:
        // 08/17/2022
        return `${parts.month}/${parts.day}/${parts.year}`;
    }

    getFormattedTimestamp(date: Date): string {
        return date.toLocaleString();
    }

    // Get a string representation of a date for the picker
    getDatePickerString(date: Date): string {
        let parts = this.extractDateParts(date);
        return [parts.year, parts.month, parts.day].join('-');
    }

    extractDateParts(date: Date): { day: string; month: string; year: string; } {
        let isoString = date.toISOString();
        // isoString will be in the format:
        // 2022-08-17T00:00:00.000Z
        let year = isoString.substring(0, 4);
        let month = isoString.substring(5, 7);
        let day = isoString.substring(8, 10);
        return { day: day, month: month, year: year };
    }

    currency(value: number): string {
        return this.currencyFormat.format(value);
    }

    weight(value: number): string {
        return this.weightFormat.format(value);
    }

    int(value: number): string {
        return this.intFormat.format(value);
    }
}