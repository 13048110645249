import { FunctionalComponent, h } from 'preact';

const BackButton: FunctionalComponent = (props) => {
    const clickHandler = () => {
        history.back();
    }
    return (
        <span style="position: relative; top:6px; cursor: pointer; margin-right:8px; user-select: none;" onClick={clickHandler}>
            <svg xmlns="http://www.w3.org/2000/svg" fill="#BE1E2D" height="48" width="48"><path d="M24 40 8 24 24 8l2.1 2.1-12.4 12.4H40v3H13.7l12.4 12.4Z" /></svg>
        </span>
    );
};

export default BackButton;
