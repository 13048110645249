import { Fragment, FunctionalComponent, h } from 'preact';
import style from './modalstyles.css';
import { LanguageService } from '../../../services/language.service';
import Button, { ButtonColor } from '../../../components/button';
import { UnitPartOrderLine } from '../../../models';
import { Dispatch, StateUpdater, useEffect, useState } from 'preact/hooks';

export interface newLineModalProps {
    /**
     * Indicates whether the dialog is shown.
     */
    shown: boolean;

    /**
     * Indicates whether user is editing an object or creating a new one.
     */
    isEdit: boolean;

    /**
     * The line item user is editing.
     */
    line: UnitPartOrderLine;

    /**
     * Called when dialog is closed. Be sure to set shown to false in this handler.
     * @param result Whether the user wants to perform the action or not.
     */
    onClose: (result?: UnitPartOrderLine) => void;
}

const NewLineItemModal: FunctionalComponent<newLineModalProps> = (props) => {
    const language = new LanguageService();

    const [valid, setValid] = useState(false);
    const [pOLine, setPOLine] = useState(props.line.POLine);
    const [partNumber, setPartNumber] = useState(props.line.PartNumber);
    const [description, setDescription] = useState(props.line.Description);
    const [orderQty, setOrderQty] = useState(props.line.OrderQty);
    const [vehicleModel, setVehicleModel] = useState(props.line.VehicleModel);
    const [vehicleChassisNo, setVehicleChassisNo] = useState(props.line.VehicleChassisNo);
    const [purpose, setPurpose] = useState(props.line.Purpose);
    const [countryModel, setCountryModel] = useState(props.line.CountryModel);

    useEffect(() => {
        setValid(fromState().valid());
    });

    const fromState = (): UnitPartOrderLine => {
        let val = new UnitPartOrderLine();
        val.POLine = +pOLine;
        val.PartNumber = partNumber;
        val.Description = description;
        val.OrderQty = +orderQty;
        val.VehicleModel = vehicleModel;
        val.VehicleChassisNo = vehicleChassisNo;
        val.Purpose = purpose;
        val.CountryModel = countryModel;
        return val;
    }

    const setValue = (event: any, setter: (value: any) => void) => {
        setter(event.target.value);
    }

    const textBox = (label: string, value: string, updater: Dispatch<StateUpdater<string>>, maxLength: number, autoFocus = false) => {
        return (<div class={style.textWrapper}>
            <label class={style.label}>{language.translate(label)}:  <span class={style.required}>*</span></label>
            <input type="text" class={style.input} value={value} onInput={(v) => setValue(v, updater)} maxLength={maxLength} autoFocus={autoFocus} />
        </div>);
    }
    const number = (label: string, value: number, updater: Dispatch<StateUpdater<number>>) => {
        return (<div class={style.textWrapper}>
            <label class={style.label}>{language.translate(label)}:  <span class={style.required}>*</span></label>
            <input type="number" class={style.input} value={value} onChange={(v) => setValue(v, updater)} />
        </div>);
    }
    if (!props.shown) {
        return <Fragment></Fragment>
    }
    return (
        <div class={style.modal} style={{ display: props.shown ? 'block' : 'none' }}>
            <div class={style.modalContent} onClick={(event) => event.stopPropagation()}>
                <h1>{language.translate(props.line ? 'Edit Row' : 'New Row')}</h1>
                <div class={style.textBoxContainer}>
                    {number('PO Line', pOLine, setPOLine)}
                    {textBox('Part Number', partNumber, setPartNumber, 15, true)}
                    {textBox('Description', description, setDescription, 30)}
                    {number('Order Qty', orderQty, setOrderQty)}
                    {textBox('Vehicle Model', vehicleModel, setVehicleModel, 30)}
                    {textBox('Vehicle Chassis No', vehicleChassisNo, setVehicleChassisNo, 30)}
                    <div class={style.textWrapper}>
                        <label class={style.label}>{language.translate('Purpose')}:  <span class={style.required}>*</span></label>
                        <select class={style.input} value={purpose} onInput={(v) => setValue(v, setPurpose)}>
                            <option value="S">S - Stock</option>
                            <option value="C">C - Customer</option>
                            <option value="N">N - Initial Stock</option>
                        </select>
                    </div>
                    {textBox('Country Model', countryModel, setCountryModel, 30)}
                </div>
                <div style="display:flex; margin-top:18px">
                    <span style="flex:1"></span>
                    <Button text={language.translate('Cancel')} color={ButtonColor.Secondary} onClick={() => props.onClose()} style="margin-right:12px;" />
                    <Button text={language.translate('Save')} onClick={() => props.onClose(fromState())} disabled={!valid} />
                </div>
            </div>
        </div>
    );
}

export default NewLineItemModal;