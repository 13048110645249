import { FunctionalComponent, h } from 'preact';
import { route } from 'preact-router';
import { useEffect, useState } from 'preact/hooks';
import BackButton from '../../../../components/back-button';
import LoadingIndicator from '../../../../components/loading-indicator';
import { AdminService } from '../../../../services/admin.service';
import { LanguageService } from '../../../../services/language.service';
import { SessionService } from '../../../../services/session.service';
import { Customer } from '../../../../models';
import CustomerDetail from '../../../../components/customer-detail/customer-detail';

const AdminCustomerDetail: FunctionalComponent<any> = ({ id, ...props }) => {
    const language = new LanguageService();
    const admin = new AdminService();

    const [details, setDetails] = useState<Customer>();
    const session = new SessionService();
    if (session.authenticated() === false) {
        route('/');
    }

    const [loading, setLoading] = useState(true);
    const [initialLoad, setInitialLoad] = useState(false);
    useEffect(() => {
        admin.customerDetail(id).then(user => {
            setDetails(user);
            setLoading(false);
        })
    }, [initialLoad]);

    const updateCustomer = (newVal: Customer) => {
        admin.customerUpdate(newVal).then(result => {
            route('/admin/customers');
        })
    }

    return (
        <div>
            <LoadingIndicator loading={loading}>
            <h1><BackButton />{language.translate('Customers / ') + details?.Name}</h1>
            {details && <CustomerDetail allowEditID={false} labelText='Update Customer' customer={details} onSave={updateCustomer} />}
            </LoadingIndicator>
        </div>
    );


};

export default AdminCustomerDetail;