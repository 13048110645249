import { FunctionalComponent, h } from 'preact';
import { route } from 'preact-router';
import { useEffect, useState } from 'preact/hooks';
import BackButton from '../../../components/back-button';
import DateDisplay from '../../../components/date-display';
import LoadingIndicator from '../../../components/loading-indicator';
import { APIService } from '../../../services/api.service';
import { FormatterService } from '../../../services/formatter.service';
import { LanguageService } from '../../../services/language.service';
import { SessionService } from '../../../services/session.service';
import style from './style.css';
import OrderDetailDownload from './detail-download';

const OrderDetail: FunctionalComponent<any> = ({ id, ...props }) => {
    const format = new FormatterService();
    const language = new LanguageService();
    const api = new APIService();

    const [details, setDetails] = useState<any>();
    const session = new SessionService();
    if (session.authenticated() === false) {
        route('/');
    }


    const [loading, setLoading] = useState(true);
    const [initialLoad, setInitialLoad] = useState(false);
    useEffect(() => {
        api.orderDetail(id).then(results => {
            setDetails(results);
            setLoading(false);
        });
    }, [initialLoad]);

    const shipTo = () => {
        return (
            !details ? <p>Loading</p> :
                <div>
                    <p class={style.shipLine}>{details.Headers[0].customerName}</p>
                    <p class={style.shipLine} style="margin-top:-10px">{details.Headers[0].shipToAddressLine1}</p>
                    <p class={style.shipLine} style="margin-top:-10px">{details.Headers[0].shipToAddressLine2}</p>
                    <p class={style.shipLine} style="margin-top:-10px">{details.Headers[0].shipToAddressLine3}</p>
                </div>
        );
    }

    const headerTable = () => {
        return (
            !details ? <p>Loading</p> :
                <table>
                    <thead>
                        <tr>
                            <th>{language.translate('Customer Order')}</th>
                            <th>{language.translate('HMM Order')}</th>
                            <th>{language.translate('Date Entered')}</th>
                            <th>{language.translate('Route')}</th>
                            <th>{language.translate('Ship Via')}</th>
                            <th>{language.translate('Total Amt.')}</th>
                            <th>{language.translate('Rem. Amt.')}</th>
                            <th>{language.translate('Total Lines')}</th>
                            <th>{language.translate('Rem. Lines')}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {details.Headers.map((item: any) =>
                            <tr key={item.hmmOrderNumber}>
                                <td>{item.customerOrderNumber}</td>
                                <td>{item.hmmOrderNumber}</td>
                                <td><DateDisplay date={item.orderDate} /></td>
                                <td>{item.route}</td>
                                <td>{item.shipVia}</td>
                                <td>$ <span style="float: right;">{format.currency(item.totalAmount)}</span></td>
                                <td>$ <span style="float: right;">{format.currency(item.remainingAmount)}</span></td>
                                <td>{item.totalLines}</td>
                                <td>{item.remainingLines}</td>
                            </tr>)
                        }
                    </tbody>
                </table>)
    }

    const linesTable = () => {
        return (
            !details ? <p>Loading</p> :
                <table>
                    <thead>
                        <tr>
                            <th>{language.translate('HMM Order')}</th>
                            <th>{language.translate('Line')}</th>
                            <th>{language.translate('Part Number')}</th>
                            <th>{language.translate('Description')}</th>
                            <th>{language.translate('Item Price')}</th>
                            <th>{language.translate('Qty. Ordered')}</th>
                            <th>{language.translate('Qty. Allocated')}</th>
                            <th>{language.translate('Qty. Packed')}</th>
                            <th>{language.translate('Qty. Shipped')}</th>
                            <th>{language.translate('Ext. Price')}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {details.Lines.map((item: any) =>
                            <tr key={item.HMMOrderNumber + ' - ' + item.CustomerOrderLine}>
                                <td>{item.HMMOrderNumber}</td>
                                <td>{item.CustomerOrderLine}</td>
                                <td>{item.PartNumber}</td>
                                <td>{item.Description}</td>
                                <td>$ <span style="float: right;">{format.currency(item.Price)}</span></td>
                                <td>{item.QTYOrdered}</td>
                                <td>{item.QTYAllocated}</td>
                                <td>{item.QTYPacked}</td>
                                <td>{item.QTYShipped}</td>
                                <td>$ <span style="float: right;">{format.currency(item.ExtendedPrice)}</span></td>
                            </tr>)
                        }
                    </tbody>
                </table>)
    }

    return (
        <div>
            <h1><BackButton />{language.translate('Order #') + id} <OrderDetailDownload id={id} /></h1>
            <LoadingIndicator loading={loading}>
                <div style="display: flex">
                    <div style="margin-right:12px"><p class={style.shipLine}>{language.translate('Ship To:')}</p></div>
                    {shipTo()}
                </div>
                <p class={style.shipLine}>{language.translate('Order Header:')}</p>
                {headerTable()}
                <p class={style.shipLine}>{language.translate('Order Detail:')}</p>
                {linesTable()}
            </LoadingIndicator>
        </div>
    );


};

export default OrderDetail;