import { FunctionalComponent, h } from 'preact';
import { LanguageService } from '../../services/language.service';
import style from './style.css';

const SupersessionListModal: FunctionalComponent<{ isOpen: boolean, loading: boolean, items: any[], closeModal: () => void; }> = (props) => {
    const language = new LanguageService();
    return (
        <div class={style.modal} style={{ display: props.isOpen ? 'block' : 'none' }} onClick={props.closeModal}>
            <div class={style.modalContent} onClick={(event) => event.stopPropagation()}>
                <h1>{language.translate('Supersession List')} <span class={style.close} onClick={props.closeModal}>&times;</span></h1>
                {props.loading && <progress></progress>}
                {!props.loading &&
                    <table>
                        <thead>
                            <tr>
                                <th>{language.translate('Group')}</th>
                                <th>{language.translate('Seq.')}</th>
                                <th>{language.translate('Part Number')}</th>
                                <th>{language.translate('Code')}</th>
                                <th>{language.translate('Description')}</th>
                                <th>{language.translate('Price')}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {props.items.map(item =>
                                <tr key={item.GroupNum + item.PartNumber} style={{ backgroundColor: item.Sequence == 1 ? '#cce9ff' : '#EEEEEE' }}>
                                    <td>{item.GroupNum}</td>
                                    <td>{item.Sequence}</td>
                                    <td>{item.PartNumber}</td>
                                    <td>{item.Code}</td>
                                    <td>{language.isSpanish() ? item.ItemDescriptionES : item.ItemDescriptionEN}</td>
                                    <td>{item.ListPrice}</td>
                                </tr>)
                            }
                        </tbody>
                    </table>}
            </div>
        </div>
    );
}

export default SupersessionListModal;