import { Fragment } from 'preact';
import { FunctionalComponent, h } from 'preact';
import { FormatterService } from '../../services/formatter.service';
import { LanguageService } from '../../services/language.service';

export class dateProps {
    date?: string;
    timestamp?: boolean;
}

const DateDisplay: FunctionalComponent<dateProps> = (props) => {
    const ls = new LanguageService();
    const format = new FormatterService();
    const getDisplay = (date: string | undefined) => {
        if (date) {
            let dateObj = new Date(date);
            // Zero date
            if (dateObj.getFullYear() < 2000) {
                return '';
            }
            if (props.timestamp === true) {
                return format.getFormattedTimestamp(dateObj);
            }
            return format.getFormattedDate(dateObj);
        }
        return "";
    }
    return (
        <Fragment>{getDisplay(props.date)}</Fragment>
    );
};
export default DateDisplay;
