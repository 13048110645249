import { Fragment } from 'preact';
import { FunctionalComponent, h } from 'preact';
import { useState } from 'preact/hooks';
import { FormatterService } from '../../services/formatter.service';
import { LanguageService } from '../../services/language.service';
import style from './style.css';

export class datePickerProps {
    /**
     * The label for your date picker
     */
    label: string = '';

    /**
     * The ID for the control
     */
    id: string = '';

    selectedDate?: Date;

    onChange?: (newValue: Date | undefined) => void;
}


const DatePicker: FunctionalComponent<datePickerProps> = (props) => {
    const ls = new LanguageService();
    const format = new FormatterService();

    // Get a string representation of a date for the picker
    const valueFromDate = (date: Date | undefined): string => {
        if (date instanceof Date) {
            return format.getDatePickerString(date);
        }
        return '';
    }

    const [selectedVal, setSelectedVal] = useState(valueFromDate(props.selectedDate));

    const valueChanged = (event: any) => {
        let val = event.target.value;
        setSelectedVal(val);
        let date = new Date(val);
        // Determine if date is valid
        if (Number.isNaN(date.getTime())) {
            return;
        }
        if (props.onChange) {
            props.onChange(date);
        }
    }

    return (
        <Fragment>
            <label class={style.label} for={props.id}>{ls.translate(props.label)}</label>
            <input type="date" style="width:200px; margin-right:10px;" id={props.id} name={props.id} value={selectedVal} onChange={valueChanged} />
        </Fragment>
    );
};

export default DatePicker;
