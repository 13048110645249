import { FunctionalComponent, h } from 'preact';
import { Route, Router } from 'preact-router';

import Home from '../routes/home';
import ProductSearch from '../routes/product/search';
import NotFoundPage from '../routes/notfound';
import Header from './header';
import OrderSearch from '../routes/order/search';
import ShipmentSearch from '../routes/shipment/search';
import OrderDetail from '../routes/order/detail';
import ShipmentDetail from '../routes/shipment/detail';
import AdminUserList from '../routes/admin/users/list';
import AdminUserDetail from '../routes/admin/users/detail';
import AdminCustomers from '../routes/admin/customers';
import RegularOrder from '../routes/new-order/regular';
import UnitPartOrder from '../routes/new-order/unit-part';
import AdminOrderStatus from '../routes/admin/order-status';
import AdminLookups from '../routes/admin/lookups';
import OrderSubmitted from '../routes/new-order/submitted';
import AdminCustomerDetail from '../routes/admin/customers/detail';

const App: FunctionalComponent = () => {
    return (
        <div id="preact_root">
            <Header />
            <div style="padding: 56px 20px; width: 100%;">
                <Router>
                    <Route path="/" component={Home} />
                    <Route path="/product/search" component={ProductSearch} />
                    <Route path="/order/search" component={OrderSearch} />
                    <Route path="/order/search/:id" component={OrderDetail} />
                    <Route path="/shipment/search" component={ShipmentSearch} />
                    <Route path="/shipment/search/:id" component={ShipmentDetail} />
                    <Route path="/new-order/regular" component={RegularOrder} />
                    <Route path="/new-order/unit-part" component={UnitPartOrder} />
                    <Route path="/new-order/submitted" component={OrderSubmitted} />
                    <Route path="/admin/users" component={AdminUserList} />
                    <Route path="/admin/users/:id" component={AdminUserDetail} />
                    <Route path="/admin/customers" component={AdminCustomers} />
                    <Route path="/admin/customers/:id" component={AdminCustomerDetail} />
                    <Route path="/admin/order-status" component={AdminOrderStatus} />
                    <Route path="/admin/lookups" component={AdminLookups} />
                    <NotFoundPage default />
                </Router>
            </div>
        </div>
    );
};

export default App;
