export class UnitPartOrderLine {
    POLine: number = 0;
    PartNumber: string = '';
    Description: string = '';
    OrderQty: number = 0;
    VehicleModel: string = '';
    VehicleChassisNo: string = '';
    Purpose: string = '';
    CountryModel: string = '';

    constructor() {

    }

    valid(): boolean {
        return this.POLine > 0 &&
            this.PartNumber != '' &&
            this.Description != '' &&
            this.OrderQty > 0 &&
            this.VehicleModel != '' &&
            this.VehicleChassisNo != '' &&
            this.Purpose != '' &&
            this.CountryModel != '';
    }
}