import { AuthResponse, OrderSearchParams, OrderUploadResponse, ShipmentSearchParams, UnitPartOrderHeader, UnitPartOrderLine } from "../models";
import { HttpService } from "./http.service";
import { SessionService } from "./session.service";


export class APIService {
    private readonly apiPrefix = '/api';
    http: HttpService;
    session: SessionService;
    constructor() {
        this.http = new HttpService();
        this.session = new SessionService();
    }

    /**
     * Attempt to authenticate
     * @param email The email address the user is attempting to log in with
     * @param password The password the user is attempting to log in with
     */
    login(email: string, password: string): Promise<boolean> {
        return this.http.post<AuthResponse>(`${this.apiPrefix}/login`, { email: email, password: password })
            .then(response => {
                // If we succeeded, set token and return true.
                if (response.success) {
                    this.session.setCurrentUser(response);
                    return true;
                }
                return false;
            }).catch(err => {
                return false
            });
    }

    /**
     * Search products
     * @param partNumbers The part numbers you want to search for. We will string.split this string to turn into array.
     */
    searchProducts(partNumbers: string) {
        return this.http.post<any>(`${this.apiPrefix}/search`, { partNumbers: this.splitString(partNumbers) });
    }

    /**
     * Retrieve supersession list for a part.
     * @param partNumber The part number to retrieve a supersession list for
     */
    supersessionList(partNumber: string) {
        return this.http.post<any>(`${this.apiPrefix}/supersessionList/${partNumber}`)
    }

    /**
     * Retrieve a kit list for a part.
     * @param partNumber The part number to retrieve a kit list for
     */
    kitList(partNumber: string) {
        return this.http.post<any>(`${this.apiPrefix}/kitList/${partNumber}`);
    }

    /**
     * Search orders.
     * @param params The search params
     */
    orderSearch(params: OrderSearchParams) {
        let payload = {
            ...params,
            OrderNumbers: this.splitString(params.OrderNum),
        }
        return this.http.post<any[]>(`${this.apiPrefix}/order/search`, payload);
    }

    /**
     * Search orders and generate excel.
     * @param params The search params
     */
    orderSearchDownload(params: OrderSearchParams) {
        let payload = {
            ...params,
            OrderNumbers: this.splitString(params.OrderNum),
        }
        return this.http.download(`${this.apiPrefix}/report/order/search`, payload);
    }

    orderDetail(orderID: string) {
        orderID = encodeURIComponent(orderID)
        return this.http.get<any>(`${this.apiPrefix}/order/${orderID}`);
    }

    orderDetailDownload(orderID: string) {
        orderID = encodeURIComponent(orderID)
        return this.http.download(`${this.apiPrefix}/report/order/detail/${orderID}`);
    }

    orderUpload(order: File, params: any): Promise<OrderUploadResponse> {
        const form = new FormData();
        form.append("params", JSON.stringify(params));
        form.append("order", order);
        return this.http.upload(`${this.apiPrefix}/order/regular`, form).then(resp => JSON.parse(resp));
    }

    shipmentSearch(params: ShipmentSearchParams) {
        let payload = {
            ...params,
            OrderNumbers: this.splitString(params.SearchText),
        }
        return this.http.post<any[]>(`${this.apiPrefix}/shipment/search`, payload)
    }

    shipmentSearchDownload(params: ShipmentSearchParams) {
        let payload = {
            ...params,
            OrderNumbers: this.splitString(params.SearchText),
        }
        return this.http.download(`${this.apiPrefix}/report/shipment/search`, payload)
    }

    shipmentDetail(id: string) {
        return this.http.get<any[]>(`${this.apiPrefix}/shipment/${id}`);
    }

    shipmentDetailDownload(id: string) {
        return this.http.download(`${this.apiPrefix}/report/shipment/detail/${id}`);
    }

    lookupSourceCodes() {
        return this.http.get<any[]>(`${this.apiPrefix}/lookups/sourcecode`);
    }

    createUnitPartOrder(header: UnitPartOrderHeader, lines: UnitPartOrderLine[]): Promise<OrderUploadResponse> {
        return this.http.post<OrderUploadResponse>(`${this.apiPrefix}/unit-part-order`, {header: header, lines: lines});
    }

    splitString(val: string): string[] {
        return val.replace("\t", " ").split(/[ ,]+/)
    }
}