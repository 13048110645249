/**
 * A service to encapsulate language preferences and get translations of certain text.
 */
export class LanguageService {
    private readonly _prefKey = 'language-pref';
    constructor() { }

    /**
     * Return the current users' language preference = spanish.
     */
    isSpanish(): boolean {
        return this.getLanguage() === 'es';
    }

    wrapLocalStorageGet(key: string): string | null {
        if (typeof window !== "undefined") {
            return localStorage.getItem(key);
        }
        return null;
    }

    /**
     * Get the current users' language pref.
    */
    getLanguage(): string {
        let lang = this.wrapLocalStorageGet(this._prefKey);
        // They haven't selected a language. Let's default it to browser pref
        if (lang === null) {
            // English
            if (typeof window !== "undefined" && navigator.language.startsWith('en')) {
                lang = 'en';
            } else {
                // Otherwise assume Spanish
                lang = 'es';
            }
        }
        return lang;
    }

    /**
     * Set the current users' language pref.
     * @param languageTag Either en or es.
     */
    setLanguage(languageTag: 'en' | 'es') {
        if (typeof window !== "undefined") {
            localStorage.setItem(this._prefKey, languageTag);
        }
    }

    /**
     * Anything you want translated, add English version followed by Spanish version.
     */
    static readonly translations: any = {
        "Login": "Acceso",
        "Email": "Correo electrónico",
        "Password": "Clave",
        "Product Search": "Búsqueda de Producto",
        "Enter product number(s) and press enter to search.": "Ingrese los números de producto y presione enter para buscar.",
        "No products found.": "No se encontraron productos",
        "Enter part numbers, separated by a space or comma": "Introduzca los números de pieza, separados por un espacio o una coma",
        "There was a problem logging in. Check your email and password.": "Hubo un problema al iniciar sesión. Verifique su correo electrónico y contraseña.",
        "Part Number": "Número de parte",
        "Description": "Descripción",
        "Available Intransit Qty": "Inv. en Tránsito Disp",
        "HMM Inv.": "Inv. HMM",
        "HML Inv.": "Inv. HML",
        "China Inv.": "Inv. China",
        "Price": "Precio",
        "Weight (KG)": "Peso (KG)",
        "Dim. (L)": "Dim. (L)",
        "Dim. (W)": "Dim. (A)",
        "Dim. (H)": "Dim. (A)",
        "Superseded By": "Intercambiable",
        "Replace part with superseding": "Reemplazar parte con reemplazar",
        "Supersession List": "Lista de Intercambiale",
        "Note": "Nota",
        "Seq.": "Sec.",
        "Code": "Código",
        "Group": "Grupo",
        "Qty.": "Cant.",
        "Kit Component": "Componentes",
        "Yes": "Sí",
        "Kit": "Kit",
        "Air": "Aérea",
        "SEA/Truck": "Mar/Camión",
        "Stock Air": "Stock Aéreo",
        "Customer": "Número de Cliente",
        "Customer Order": "Número de Orden de Cliente",
        "HMM Order": "Orden HMM",
        "Date Entered": "Fecha Ingresada",
        "Route": "Ruta",
        "Ship Via": "Vía",
        "Total Amt.": "Monto Total",
        "Rem. Amt.": "Monto Rest.",
        "Complete?": "Completo",
        "No orders found.": "No se encontraron orden.",
        "Search is limited to 150 days after order closed.": "Nota: Ordenes completas despues de 150 días no se muestran.",
        "Search": "Búsqueda",
        "Reset": "Reajustar",
        "No shipments found.": "No se han encontrado embarques.",
        "Search by Order Number (Customer or HMM) or Part Number": "Número de Orden de Cliente O Número de Orden de HMM",
        "Search by order numbers (Customer or HMM), Part number or Invoice number": "Búsqueda por Número de Orden (de Cliente o de HMM), Número de Parte o Número de Factura",
        "Advanced Search": "Búsqueda Avanzada",
        "Total Lines": "Total de Líneas",
        "Rem. Lines": "Líneas Restantes",
        "Line": "Línea",
        "Ship To:": "Enviar a:",
        "Order Header:": "Encabezado de Orden:",
        "Order Detail": "Detalle de Orden",
        "Order Detail:": "Detalle de Orden:",
        "Item Price": "Precio",
        "Qty. Ordered": "Cant. Ordenada",
        "Qty. Allocated": "Cant. Asignada",
        "Qty. Packed": "Cant. Empacada",
        "Qty. Shipped": "Cant. Enviada",
        "Ext. Price": "Precio Ext.",
        "Order #": "Orden #",
        "Order": "Orden",
        "Order date from:": "Fecha de Orden Desde:",
        "to:": "A:",
        "Include Closed Orders?": "Incluir Ordenes Completos?",
        "Route selection:": "Selección de Ruta:",
        "From Invoice Number:": "De Número de Factura:",
        "Invoice No.": "Factura",
        "Invoice": "Factura",
        "Amount": "Monto",
        "AWB/Manif.": "Número de Guia",
        "Ship Date": "Fecha Embarque",
        "Arrival Date": "Fecha Llegada",
        "No. of Cases": "No. of Cajas",
        "Total Weight (KG)": "Peso Total (KG)",
        "Search is limited to 150 days after shipment.": "Solo se muestran las facturas hasta 150 días despues del embarque.",
        "Invoice #": "Factura ",
        "Shipment Header:": "Encabezado de Embarque:",
        "Shipment Detail:": "Detalle de Embarque:",
        "Case": "Caja",
        "Shipment Search": "Búsqueda de envío",
        "Order Search": "Búsqueda de Orden",
        "Download": "Descargar",
        "Regular Order": "Pedidos Regulares",
        "Regular Stock": "Stock Regular",
        "Created Demand": "Demanda Creada",
        "Order Type": "Tipo de Pedido",
        "Source Code": "Codigo",
        "Notes": "Notas",
        "Unit Part Order": "Pedido de Repuestos Unitarios (UNIT PARTS)",
        "Click to Select Order Details": "Cargue los detalles de su Pedido",
        "Supports txt and csv": "admite texto y csv",
        "Please fix the following errors": "Por favor corrige los siguientes errores",
        "New Order": "Nueva Orden",
        "Unit Parts": "Repuestos Unitarios",
        "PO Number": "Número de Pedido",
        "Order Details": "Detalles del pedido",
        "Add New Row": "Añadir nueva fila",
        "Submit Order": "Orden de envio",
        "PO Line": "Línea",
        "Order Qty": "Cantidad",
        "Vehicle Model": "Modelo de Vehículo",
        "Vehicle Chassis No": "Número de Chasís",
        "Purpose": "Propósito",
        "Country Model": "País del Model",
        "Save": "Ahorrar",
        "Cancel": "Cancelar",
        "Edit Row": "Editar fila",
        "Order Submitted": "Orden Enviada",
        "Thank you for your submission. Your order will appear in the order search once it has been processed.": "Gracias por tu envío. Su pedido aparecerá en la búsqueda de pedidos una vez que haya sido procesado.",
        "SEA": "MARÍTIMO",
        "SAIR": "STOCK AÉREO",
        "TRK": "CAMIÓN",
        "AIR": "AÉREO",
        "Regular AIR": "Aéreo Regular",
        "Your AIR order exceeds the line maximum of 25 lines. Please reduce the order lines to 25 lines or send a SAIR order.": "Su pedido AÉREO excede el máximo de 25 líneas. Reduzca las líneas de pedido a 25 líneas o envíe un pedido SAIR.",
    }

    /**
     * Translate a string to users' selected language preference. Most of the developers 
     * on this project have English as their first language, so it allows us to develop 
     * in English but also support Spanish.
     * @param en English to translate (will return if user prefers English).
     */
    translate(en: string): string {
        // Not Spanish, so just return English.
        if (!this.isSpanish()) {
            return en;
        }
        let val = LanguageService.translations[en];
        if (val) {
            return val;
        }
        // Looks like we don't have a translation for this item.
        return en;
    }
}