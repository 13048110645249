import { Attributes, Component, ComponentChild, ComponentChildren, h, Ref } from 'preact';
import { route } from 'preact-router';
import LoadingIndicator from '../../../../components/loading-indicator';
import { AdminService } from '../../../../services/admin.service';
import { LanguageService } from '../../../../services/language.service';
import { SessionService } from '../../../../services/session.service';
import style from './style.css';

class AdminUserAdd extends Component {
    state = {
        loading: false,
        email: '',
        customerNo: '',
        fullName: '',
        newPassword: '',
    }
    language = new LanguageService();
    admin = new AdminService();
    session = new SessionService();
    
    constructor() {
        super();
        if (this.session.authenticated() === false) {
            route('/');
        }
    }

    createUser = () => {
        if (this.state.customerNo == '' || this.state.email == '' || this.state.fullName == '' || this.state.newPassword == '') {
            return;
        }
        this.setState({loading: true});
        this.admin.userCreate(this.state.customerNo, this.state.email, this.state.fullName, this.state.newPassword).then(result => {
            window.location.reload();
        }).catch(error => {
            this.setState({loading: false});
            alert('Problem creating user. Check to make sure email is not in use. Also make sure customer number is valid.');
        })
    }

     formInput = (event: any) => {
        const value = event.target.value;
        const name = event.target.name;
        this.setState({
            [name]: value
        });
    }

    render(props?: Readonly<Attributes & { children?: ComponentChildren; ref?: Ref<any> | undefined; }> | undefined, state?: Readonly<{}> | undefined, context?: any): ComponentChild {
        return (
            <div>
                <LoadingIndicator loading={this.state.loading}>
                    <h2>Create New User</h2>
                    <div class={style.inputWrapper}>
                        <label for="email">Email:</label>
                        <input type="email" name="email" id="email" value={this.state.email} onChange={this.formInput}></input>
                    </div>
    
                    <div class={style.inputWrapper}>
                        <label for="customerNo">Customer Number:</label>
                        <input type="text" name="customerNo" id="customerNo" value={this.state.customerNo} onChange={this.formInput}></input>
                    </div>
    
                    <div class={style.inputWrapper}>
                        <label for="fullName">Full Name:</label>
                        <input type="text" name="fullName" id="fullName" value={this.state.fullName} onChange={this.formInput}></input>
                    </div>
    
                    <div class={style.inputWrapper}>
                        <label for="newPassword">Password:</label>
                        <input type="password" name="newPassword" id="newPassword" value={this.state.newPassword} onChange={this.formInput}></input>
                    </div>
                    
                    <p style="clear:both; padding-top:10px; margin-bottom: 12px;"><button onClick={this.createUser}>Create User</button></p>
                </LoadingIndicator>
            </div>
        );
    }
};

export default AdminUserAdd;