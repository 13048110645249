import { FunctionalComponent, h } from 'preact';
import { Link } from 'preact-router/match';
import style from './style.css';
import { useEffect, useState } from 'preact/hooks';
import { AdminService } from '../../../services/admin.service';
import { SessionService } from '../../../services/session.service';
import { LanguageService } from '../../../services/language.service';
import DateDisplay from '../../../components/date-display';
import { saveAs } from 'file-saver';

const AdminOrderStatus: FunctionalComponent = () => {
    const admin = new AdminService();
    const session = new SessionService();
    const language = new LanguageService();

    const [results, setResults] = useState<any[]>([]);
    const [loading, setLoading] = useState(true);

    // Only changes with initialLoad, so after initial load we won't load data again.
    const [initialLoad, setInitialLoad] = useState(false);
    useEffect(() => {
        admin.customerOrdersList().then(results => {
            setResults(results);
            setLoading(false);
        })
    }, [initialLoad]);

    const download = (id: number, orderType: string) => {
        if (orderType === 'Customer') {
            admin.customerOrdersDownload(id).then(result => saveAs(result, `order.csv`));
        } else {
            admin.unitPartOrdersDownload(id).then(result => saveAs(result, `order.csv`));
        }
    }

    return (
        <div>
            <h1>Order Status</h1>
            {loading && <progress></progress>}
            {results.length > 0 &&
                <table style="margin-top: 10px; width:auto;">
                    <thead>
                        <tr>
                            <th style="padding-right:18px;">{language.translate('Order Type')}</th>
                            <th style="padding-right:18px;">{language.translate('Date Submitted')}</th>
                            <th style="padding-right:18px;">{language.translate('Customer #')}</th>
                            <th style="padding-right:18px;">{language.translate('Customer PO')}</th>
                            <th style="padding-right:18px;">{language.translate('Transmitted At')}</th>
                            <th style="padding-right:18px;"></th>
                        </tr>
                    </thead>
                    <tbody>
                        {results.map(item =>
                            <tr key={item.ID+item.OrderType}>
                                <td>{item.OrderType}</td>
                                <td><DateDisplay date={item.OrderDate} /></td>
                                <td>{item.CustomerNumber}</td>
                                <td>{item.CustomerPONumber}</td>
                                <td><DateDisplay date={item.TransmittedAt} timestamp={true} /></td>
                                <td><Link onClick={() => download(item.ID, item.OrderType)}>Download</Link></td>
                            </tr>)
                        }
                    </tbody>
                </table>
            }
        </div>
    );
};

export default AdminOrderStatus;
