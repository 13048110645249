import { Component, Fragment, h } from 'preact';
import { Link, route } from 'preact-router';
import Accordion from '../../../components/accordion';
import DateDisplay from '../../../components/date-display';
import DatePicker from '../../../components/date-picker';
import LoadingIndicator from '../../../components/loading-indicator';
import SearchBar from '../../../components/search-bar';
import { OrderSearchParams } from '../../../models';
import { APIService } from '../../../services/api.service';
import { FormatterService } from '../../../services/formatter.service';
import { LanguageService } from '../../../services/language.service';
import { SessionService } from '../../../services/session.service';
import style from './style.css';
import saveAs from 'file-saver';

const initialResults: any[] = [];

class OrderSearch extends Component {
    state = {
        searchParams: new OrderSearchParams(),
        results: initialResults,
        loading: false,
    }

    format = new FormatterService();
    api = new APIService();
    session = new SessionService();
    isAdmin = this.session.isAdmin();

    language = new LanguageService();

    searchTextChange = (newVal: string) => {
        let searchParams = this.state.searchParams;
        searchParams.OrderNum = newVal;
        this.setState({
            searchParams: searchParams,
        })
    }

    formSubmit = (event: any) => {
        event.preventDefault();
        this.doSearch(this.state.searchParams);
    }


    formReset = (event: any) => {
        //event.preventDefault();
        let params = new OrderSearchParams();
        this.setState({ searchParams: params });
        this.doSearch(params);
    }

    doSearch = (searchParams: OrderSearchParams) => {
        this.session.setOrderSearchParams(searchParams);
        this.setState({
            loading: true,
        })
        this.api.orderSearch(searchParams).then(results => {
            this.setState({
                loading: false,
                results: results
            });
        });
    }

    downloadExcel = () => {
        return new Promise<void>((resolve) => {
            this.api.orderSearchDownload(this.state.searchParams).then(blob => {
                resolve();
                saveAs(blob, `${this.language.translate('Order Search')}.xlsx`);
            });
        });
    }

    constructor() {
        super();
        if (this.session.authenticated() === false) {
            route('/');
        }
        // Grab params from state
        this.setState({
            searchParams: this.session.getOrderSearchParams(),
        })
    }

    componentDidMount(): void {
        this.doSearch(this.session.getOrderSearchParams());
    }

    toggleChecked = (id: string) => {
        let searchParams = this.state.searchParams;
        if (searchParams.Routes.includes(id)) {
            searchParams.Routes.splice(searchParams.Routes.indexOf(id), 1);
        } else {
            searchParams.Routes.push(id);
        }
        this.setState({
            searchParams: searchParams,
        })
    }

    isChecked = (id: string): boolean => {
        return this.state.searchParams.Routes.includes(id);
    }

    valueFromDate = (date: Date | null): string => {
        if (date) {
            var month = '' + (date.getMonth() + 1);
            let day = '' + date.getDate()
            let year = date.getFullYear();

            if (month.length < 2)
                month = '0' + month;
            if (day.length < 2)
                day = '0' + day;
            return [year, month, day].join('-');
        }
        return '';
    }

    setDateFrom = (date: Date | undefined) => {
        let searchParams = this.state.searchParams;
        searchParams.DateFrom = date;
        this.setState({
            searchParams: searchParams,
        });
    }

    setDateTo = (date: Date | undefined) => {
        let searchParams = this.state.searchParams;
        searchParams.DateTo = date;
        this.setState({
            searchParams: searchParams,
        });
    }

    toggleIncludeClosed = () => {
        let searchParams = this.state.searchParams;
        searchParams.IncludeClosed = !searchParams.IncludeClosed;
        this.setState({
            searchParams: searchParams,
        });
    }

    render() {
        return (
            <div>
                <h1>{this.language.translate('Order Search')}</h1>
                <p class={style.disclaimer}>{this.language.translate('Search is limited to 150 days after order closed.')}</p>
                <form onSubmit={this.formSubmit} onReset={this.formReset}>
                    <SearchBar label="Search by Order Number (Customer or HMM) or Part Number" value={this.state.searchParams.OrderNum} onChange={this.searchTextChange} onDownload={this.downloadExcel}></SearchBar>
                    <Accordion text='Advanced Search'>
                        <div style="display: flex; flex-wrap: wrap; margin-top: 18px;">
                            <DatePicker id="dateFrom" label="Order date from:" selectedDate={this.state.searchParams.DateFrom} onChange={this.setDateFrom} />
                            <DatePicker id="dateFrom" label="to:" selectedDate={this.state.searchParams.DateTo} onChange={this.setDateTo} />
                            <label class={style.checkboxLabel} for="includeClosed">{this.language.translate('Include Closed Orders?')}</label>
                            <input type="checkbox" id="includeClosed" name="includeClosed" class={style.checkbox} checked={this.state.searchParams.IncludeClosed} onClick={() => this.toggleIncludeClosed()} />
                        </div>
                        <div style="display: flex; flex-wrap: wrap; margin-top: 8px;">
                            <label class={style.label}>{this.language.translate('Route selection:')}</label>
                            {this.routeCheckbox("aeria", "Air")}
                            {this.routeCheckbox("vor", "VOR")}
                            {this.routeCheckbox("sea", "SEA/Truck")}
                            {this.routeCheckbox("stockair", "Stock Air")}
                        </div>
                    </Accordion>
                </form>
                <LoadingIndicator loading={this.state.loading}>
                    {this.state.results.length > 0 ? this.resultsTable() : <p>{this.language.translate('No orders found.')}</p>}
                </LoadingIndicator>
            </div>
        );
    }

    routeCheckbox(id: string, label: string) {
        return (<Fragment>
            <label class={style.checkboxLabel} for={id}>{this.language.translate(label)}</label>
            <input type="checkbox" id={id} name={id} class={style.checkbox} checked={this.isChecked(id)} onClick={() => this.toggleChecked(id)} />
        </Fragment>);
    }

    resultsTable() {
        return (<table style="margin-top: 10px">
            <thead>
                <tr>
                    <th>{this.language.translate('Customer Order')}</th>
                    {this.isAdmin ? <th>{this.language.translate('Customer')}</th> : null}
                    <th>{this.language.translate('HMM Order')}</th>
                    <th>{this.language.translate('Date Entered')}</th>
                    <th>{this.language.translate('Route')}</th>
                    <th>{this.language.translate('Ship Via')}</th>
                    <th>{this.language.translate('Total Amt.')}</th>
                    <th>{this.language.translate('Rem. Amt.')}</th>
                    <th>{this.language.translate('Complete?')}</th>
                </tr>
            </thead>
            <tbody>
                {this.state.results.map(item =>
                    <tr key={item.hmmOrderNumber}>
                        <td><Link href={"/order/search/" + encodeURIComponent(item.customerOrderNumber)}>{item.customerOrderNumber}</Link></td>
                        {this.isAdmin ? <td>{item.customerName}</td> : null}
                        <td>{item.hmmOrderNumber}</td>
                        <td><DateDisplay date={item.orderDate} /></td>
                        <td>{item.route}</td>
                        <td>{item.shipVia}</td>
                        <td>$ <span style="float: right;">{this.format.currency(item.totalAmount)}</span></td>
                        <td>$ <span style="float: right;">{this.format.currency(item.remainingAmount)}</span></td>
                        <td>{item.orderComplete}</td>
                    </tr>)
                }
            </tbody>
        </table>)
    }
}


export default OrderSearch;
