import { Attributes, Component, ComponentChild, ComponentChildren, h, Ref } from 'preact';
import { Link, route } from 'preact-router';
import Accordion from '../../../components/accordion';
import DateDisplay from '../../../components/date-display';
import LoadingIndicator from '../../../components/loading-indicator';
import SearchBar from '../../../components/search-bar';
import { ShipmentSearchParams } from '../../../models';
import { APIService } from '../../../services/api.service';
import { FormatterService } from '../../../services/formatter.service';
import { LanguageService } from '../../../services/language.service';
import { SessionService } from '../../../services/session.service';
import style from './style.css';
import { saveAs } from 'file-saver';


const initialResults: any[] = [];
class ShipmentSearch extends Component {
    constructor() {
        super();
        if (this.session.authenticated() === false) {
            route('/');
        }
        // Grab params from state
        this.setState({
            searchParams: this.session.getShipmentSearchParams(),
        })
    }

    state = {
        searchParams: new ShipmentSearchParams(),
        loading: false,
        results: initialResults,
    }
    format = new FormatterService();
    api = new APIService();
    session = new SessionService();
    isAdmin = this.session.isAdmin();

    language = new LanguageService();

    formSubmit = (event: any) => {
        event.preventDefault();
        this.doSearch(this.state.searchParams);
    }


    formReset = (event: any) => {
        this.setState({
            searchParams: new ShipmentSearchParams(),
        });
        this.doSearch(new ShipmentSearchParams());
    }

    searchTextChange = (newValue: string) => {
        let params = this.state.searchParams;
        params.SearchText = newValue;
        this.setState({
            earchParams: params,
        });
    }

    invoiceChange = (event: any) => {
        const value = event.target.value;
        const name = event.target.name;
        let params = this.state.searchParams;
        if (name == "invoiceFrom") {
            params.InvoiceNumFrom = value;
        } else {
            params.InvoiceNumTo = value;
        }
        this.setState({
            earchParams: params,
        });
    }

    toggleIncludeClosed(): void {
        let params = this.state.searchParams;
        params.IncludeClosed = !params.IncludeClosed;
        this.setState({
            earchParams: params,
        });
    }

    componentDidMount(): void {
        this.doSearch(this.session.getShipmentSearchParams());
    }

    doSearch = (params: ShipmentSearchParams) => {
        this.session.setShipmentSearchParams(params);
        this.setState({
            loading: true,
        });
        this.api.shipmentSearch(params).then(data => {
            this.setState({
                results: data,
                loading: false,
            })
        });
    }

    download = () => {
        return new Promise<void>((resolve) => {
            this.api.shipmentSearchDownload(this.state.searchParams).then(result => {
                resolve();
                saveAs(result, `${this.language.translate('Shipment Search')}.xlsx`);
            })
        });
    }

    render(props?: Readonly<Attributes & { children?: ComponentChildren; ref?: Ref<any> | undefined; }> | undefined, state?: Readonly<{}> | undefined, context?: any): ComponentChild {
        return (
            <div>
                <h1>{this.language.translate('Shipment Search')}</h1>
                <p class={style.disclaimer}>{this.language.translate('Search is limited to 150 days after shipment.')}</p>
                <form onSubmit={this.formSubmit} onReset={this.formReset}>
                    <SearchBar label="Search by order numbers (Customer or HMM), Part number or Invoice number" value={this.state.searchParams.SearchText} onChange={this.searchTextChange} onDownload={this.download}></SearchBar>
                    <Accordion text='Advanced Search'>
                        <div style="display: flex; flex-wrap: wrap; margin-top: 18px;">
                            <label class={style.label} for="invoiceFrom">{this.language.translate('From Invoice Number:')}</label>
                            <input type="text" style="width:200px; margin-right:10px;" id="invoiceFrom" name="invoiceFrom" value={this.state.searchParams.InvoiceNumFrom} onChange={this.invoiceChange} />
                            <label class={style.label} for="invoiceTo">{this.language.translate('to:')}</label>
                            <input type="text" style="width:200px" id="invoiceTo" name="invoiceTo" value={this.state.searchParams.InvoiceNumTo} onChange={this.invoiceChange} />
                            <label class={style.checkboxLabel} for="includeClosed">{this.language.translate('Include Closed Orders?')}</label>
                            <input type="checkbox" id="includeClosed" name="includeClosed" class={style.checkbox} checked={this.state.searchParams.IncludeClosed} onClick={() => this.toggleIncludeClosed()} />
                        </div>
                        <div style="display: flex; flex-wrap: wrap; margin-top: 8px;">
                        </div>
                    </Accordion>
                </form>
                <LoadingIndicator loading={this.state.loading}>
                    {this.state.results.length > 0 ? this.resultsTable() : <p>{this.language.translate('No shipments found.')}</p>}
                </LoadingIndicator>
            </div>
        );
    }

    resultsTable() {
        return (<table style="margin-top: 10px">
            <thead>
                <tr>
                    <th>{this.language.translate('Invoice No.')}</th>
                    { this.isAdmin ? <th>{this.language.translate('Customer')}</th> : null }
                    <th>{this.language.translate('Amount')}</th>
                    <th>{this.language.translate('Route')}</th>
                    <th>{this.language.translate('Ship Via')}</th>
                    <th>{this.language.translate('AWB/Manif.')}</th>
                    <th>{this.language.translate('Ship Date')}</th>
                    <th>{this.language.translate('Arrival Date')}</th>
                    <th>{this.language.translate('No. of Cases')}</th>
                    <th>{this.language.translate('Total Weight (KG)')}</th>
                    <th>{this.language.translate('Complete?')}</th>
                </tr>
            </thead>
            <tbody>
                {this.state.results.map(item =>
                    <tr key={item.InvoiceNumber}>
                        <td><Link href={"/shipment/search/" + encodeURIComponent(item.InvoiceNumber)}>{item.InvoiceNumber}</Link></td>
                        { this.isAdmin ? <td>{item.CustomerName}</td> : null }
                        <td>$ <span style="float: right;">{this.format.currency(item.InvoiceAmount)}</span></td>
                        <td>{item.Route}</td>
                        <td>{item.ShipVia}</td>
                        <td>{item.AirWaybill}</td>
                        <td><DateDisplay date={item.ShipDate} /></td>
                        <td><DateDisplay date={item.ArrivalDate} /></td>
                        <td>{item.NumCases}</td>
                        <td>{item.GrossWeightKG}</td>
                        <td>{item.OrderComplete}</td>
                    </tr>)
                }
            </tbody>
        </table>)
    }

};

export default ShipmentSearch;