import { FunctionalComponent, h } from 'preact';
import style from './style.css';
import { LanguageService } from '../../../services/language.service';
import { UnitPartOrderLine } from '../../../models';
import { Link } from 'preact-router';

interface orderDetailsProps {
    poNumber: string;
    lines: UnitPartOrderLine[];
    editLine: (newLine: UnitPartOrderLine) => void;
}

const UnitPartOrderDetails: FunctionalComponent<orderDetailsProps> = (props) => {
    const language = new LanguageService();

    const itemTable = () => {
        return (<table>
            <thead>
                <tr>
                    <th>{language.translate('PO Number')}</th>
                    <th>{language.translate('PO Line')}</th>
                    <th>{language.translate('Part Number')}</th>
                    <th>{language.translate('Description')}</th>
                    <th>{language.translate('Order Qty')}</th>
                    <th>{language.translate('Vehicle Model')}</th>
                    <th>{language.translate('Vehicle Chassis No')}</th>
                    <th>{language.translate('Purpose')}</th>
                    <th>{language.translate('Country Model')}</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                {props.lines.map(line => <tr>
                    <td>{props.poNumber}</td>
                    <td>{line.POLine}</td>
                    <td>{line.PartNumber}</td>
                    <td>{line.Description}</td>
                    <td>{line.OrderQty}</td>
                    <td>{line.VehicleModel}</td>
                    <td>{line.VehicleChassisNo}</td>
                    <td>{line.Purpose}</td>
                    <td>{line.CountryModel}</td>
                    <td style="text-align: end;">
                        <Link onClick={() => props.editLine(line)}><svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M12.3125 1.625C13.0938 0.84375 14.375 0.84375 15.1562 1.625L16.375 2.84375C17.1562 3.625 17.1562 4.90625 16.375 5.6875L14.875 7.1875L10.8125 3.125L12.3125 1.625ZM14.1562 7.90625L6.875 15.1875C6.5625 15.5 6.15625 15.75 5.71875 15.875L1.9375 16.9688C1.6875 17.0625 1.40625 17 1.21875 16.7812C1 16.5938 0.9375 16.3125 1 16.0625L2.125 12.2812C2.25 11.8438 2.5 11.4375 2.8125 11.125L10.0938 3.84375L14.1562 7.90625Z" fill="#BE1E2D" />
                        </svg></Link>
                    </td>
                </tr>)}
            </tbody>
        </table>)
    }

    const radioButton = (name: string, id: string, label: string, isChecked: () => boolean, setValue: (value: string) => void) => {
        return (<div>
            <label class={style.container}>{language.translate(label)}
                <input type="radio" name={name} id={id} checked={isChecked()} onClick={() => setValue(id)} />
                <span class={style.checkmark}></span>
            </label>
        </div>);
    }
    return (
        <div>
            <label class={style.label}>{language.translate('Order Details')}:</label>
            {itemTable()}
        </div>
    );
};

export default UnitPartOrderDetails;
