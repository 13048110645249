import { FunctionalComponent, h } from "preact";
import Button, { ButtonColor, Icon } from "../../../components/button";
import { APIService } from "../../../services/api.service";
import { useState } from "preact/hooks";
import style from './style.css';
import { saveAs } from 'file-saver';
import { LanguageService } from "../../../services/language.service";


const OrderDetailDownload: FunctionalComponent<any> = ({ id, ...props }) => {
    const api = new APIService();
    const language = new LanguageService();

    const [loading, setLoading] = useState(false);

    const download = () => {
        setLoading(true);
        api.orderDetailDownload(id).then(result => {
            setLoading(false);
            saveAs(result, `${language.translate('Order')} ${id}.xlsx`);
        });
    }
    return (
        <span class={style.download}>
            <Button type="button" text="Download" icon={Icon.Download} onClick={download} color={ButtonColor.Accent} disabled={loading} />
        </span>
    );
}

export default OrderDetailDownload;