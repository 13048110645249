import { FunctionalComponent, h } from 'preact';

export class loadingProps {
    /**
     * Indicates whether loading or not
     */
    loading: boolean = false;
}

/**
 * Will show progress bar if loading is set to true. Otherwise displays children. Usage:
 * 
 * ```html
 * <LoadingIndicator loading={state.loading}>
 *  <!-- Once state.loading === false, this content will show -->
 *  <p>The data is: {state.data}</p>
 * </LoadingIndicator>
 * ```
 * 
 * @param props Properties. Be sure to set loading.
 */
const LoadingIndicator: FunctionalComponent<loadingProps> = (props) => {
    return (
        <div>{props.loading ? <progress></progress> : props.children}</div>
    );
};

export default LoadingIndicator;
