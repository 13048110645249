import { FunctionalComponent, h } from 'preact';
import { LanguageService } from '../../../services/language.service';

const OrderSubmitted: FunctionalComponent<any> = ({ id, ...props }) => {
    const language = new LanguageService();
    
    return (
        <div>
            <h1>{language.translate('Order Submitted')} <svg style="width: 40px; margin-left:4px;" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path fill="#be1e2d" d="M320 16H64C37.49 16 16 37.49 16 64V448C16 474.5 37.49 496 64 496H311.2C317 501.5 323.2 506.6 329.7 511.3C326.6 511.7 323.3 512 320 512H64C28.65 512 0 483.3 0 448V64C0 28.65 28.65 0 64 0H320C355.3 0 384 28.65 384 64V198.6C378.6 200.2 373.2 201.1 368 203.1V64C368 37.49 346.5 16 320 16V16zM64 136C64 131.6 67.58 128 72 128H312C316.4 128 320 131.6 320 136C320 140.4 316.4 144 312 144H72C67.58 144 64 140.4 64 136zM280 224C284.4 224 288 227.6 288 232C288 236.4 284.4 240 280 240H72C67.58 240 64 236.4 64 232C64 227.6 67.58 224 72 224H280zM216 320C220.4 320 224 323.6 224 328C224 332.4 220.4 336 216 336H72C67.58 336 64 332.4 64 328C64 323.6 67.58 320 72 320H216zM490.3 322.3C493.5 319.2 498.5 319.2 501.7 322.3C504.8 325.5 504.8 330.5 501.7 333.7L421.7 413.7C418.5 416.8 413.5 416.8 410.3 413.7L362.3 365.7C359.2 362.5 359.2 357.5 362.3 354.3C365.5 351.2 370.5 351.2 373.7 354.3L416 396.7L490.3 322.3zM288 368C288 288.5 352.5 224 432 224C511.5 224 576 288.5 576 368C576 447.5 511.5 512 432 512C352.5 512 288 447.5 288 368zM432 496C502.7 496 560 438.7 560 368C560 297.3 502.7 240 432 240C361.3 240 304 297.3 304 368C304 438.7 361.3 496 432 496z"/></svg></h1>
            <p>{language.translate('Thank you for your submission. Your order will appear in the order search once it has been processed.')}</p>
        </div>
    );
};

export default OrderSubmitted;