import { FunctionalComponent, h } from 'preact';
import { Link } from 'preact-router/match';
import style from './style.css';
import { LanguageService } from '../../../services/language.service';
import { useState } from 'preact/hooks';
import UnitPartOrderDetails from './order-details';
import { UnitPartOrderHeader, UnitPartOrderLine } from '../../../models';
import Button, { ButtonColor } from '../../../components/button';
import NewLineItemModal from './new-line-modal';
import { APIService } from '../../../services/api.service';
import { route as Router } from 'preact-router';

const UnitPartOrder: FunctionalComponent = () => {
    const language = new LanguageService();
    const api = new APIService();

    const [route, setRoute] = useState('');
    const [unitParts, setUnitParts] = useState('');
    const [poNumber, setPONumber] = useState('');
    const [notes, setNotes] = useState('');
    const [lines, setLines] = useState<UnitPartOrderLine[]>([]);
    const [errors, setErrors] = useState<string[]>([]);

    const [loading, setLoading] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [lineToEdit, setLineToEdit] = useState(new UnitPartOrderLine());
    const [isEdit, setIsEdit] = useState(false);

    const setValue = (event: any, setter: (value: any) => void) => {
        setter(event.target.value);
    }

    const formValid = () => {
        return route != '' &&
            unitParts != '' &&
            poNumber != '' &&
            lines.length > 0 &&
            (unitParts != 'ENGINE' || route === 'SEA');
    }

    const resetOrder = () => {
        setLines([]);
        setRoute('');
        setUnitParts('');
        setPONumber('');
        setNotes('');
        setErrors([]);
    }

    const headerFromState = (): UnitPartOrderHeader => {
        return {
            OrderRoute: route,
            UnitParts: unitParts,
            CustomerPONumber: poNumber,
            Notes: notes,
        };
    }

    const submitOrder = () => {
        setLoading(true);
        api.createUnitPartOrder(headerFromState(), lines).then(result => {
            setLoading(false);
            if (result.success === true) {
                resetOrder();
                Router('/new-order/submitted');
            } else {
                setErrors(result.errorList)
            }
        });
    }

    const addNew = () => {
        var newLine = new UnitPartOrderLine();
        newLine.POLine = lines.length + 1;
        setLineToEdit(newLine);
        setIsEdit(false);
        setTimeout(() => {
            setShowModal(true);
        }, 100);

    }

    const editLine = (line: UnitPartOrderLine) => {
        setLineToEdit(line);
        setIsEdit(true);
        setShowModal(true);
    }

    const handleModalClose = (item?: UnitPartOrderLine) => {
        if (item) {
            if (isEdit) {
                lines.splice(lines.indexOf(lineToEdit), 1, item);
            } else {
                lines.push(item);
            }
            setLines(lines);
        }
        setShowModal(false);
    }

    const radioButton = (name: string, id: string, label: string, isChecked: () => boolean, setValue: (value: string) => void) => {
        return (<div>
            <label class={style.container}>{language.translate(label)}
                <input type="radio" name={name} id={id} checked={isChecked()} onClick={() => setValue(id)} />
                <span class={style.checkmark}></span>
            </label>
        </div>);
    }

    return (
        <div>
            {showModal && <NewLineItemModal shown={showModal} onClose={handleModalClose} line={lineToEdit} isEdit={isEdit} />}
            <h1>{language.translate('Unit Part Order')}</h1>
            <div style="width:100%; max-width: 600px;">
                <div style="display: flex; flex-wrap: wrap; margin-top: 8px;">
                    <label class={style.label}>{language.translate('Unit Parts')}: <span class={style.required}>*</span></label>
                    {radioButton("unitParts", "CAB", "CAB", () => unitParts === "CAB", setUnitParts)}
                    {radioButton("unitParts", "OTH", "OTH", () => unitParts === "OTH", setUnitParts)}
                    {/* Hiding engine for now. */}
                    {/* {route === 'SEA' && radioButton("unitParts", "ENGINE", "ENGINE", () => unitParts === "ENGINE", setUnitParts)} */}
                </div>
                <div style="display: flex; flex-wrap: wrap; margin-top: 8px;">
                    <label class={style.label}>{language.translate('Route')}: <span class={style.required}>*</span></label>
                    {radioButton("route", "SEA", "SEA", () => route === "SEA", setRoute)}
                    {radioButton("route", "TRK", "TRK", () => route === "TRK", setRoute)}
                </div>
                <div style="display: flex; margin-top: 8px;">
                    <label class={style.label}>{language.translate('PO Number')}:  <span class={style.required}>*</span></label>
                    <input type="text" style="margin-left:8px" value={poNumber} onChange={(v) => setValue(v, setPONumber)} maxLength={15} />
                </div>
                <div style="display: flex; margin-top: 8px;">
                    <label class={style.label}>{language.translate('Notes')}:</label>
                    <input type="text" style="margin-left:8px" value={notes} onChange={(v) => setValue(v, setNotes)} maxLength={50} />
                </div>
            </div>
            {errors.length > 0 &&
                <div>
                    <h2>{language.translate('Please fix the following errors')}:</h2>
                    {errors.map(error =>
                        <p key={error} class={style.error}>{error}</p>)}
                </div>}
            <UnitPartOrderDetails lines={lines} poNumber={poNumber} editLine={editLine} />
            <Link style="float:right; margin-top:18px; clear:both;" onClick={() => addNew()}>{language.translate('Add New Row')}<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" style="position:relative; top:2px; margin-left: 12px">
                <path d="M7.5 11V8.5H5C4.71875 8.5 4.5 8.28125 4.5 8C4.5 7.75 4.71875 7.5 5 7.5H7.5V5C7.5 4.75 7.71875 4.5 8 4.5C8.25 4.5 8.5 4.75 8.5 5V7.5H11C11.25 7.5 11.5 7.75 11.5 8C11.5 8.28125 11.25 8.5 11 8.5H8.5V11C8.5 11.2812 8.25 11.5 8 11.5C7.71875 11.5 7.5 11.2812 7.5 11ZM16 8C16 12.4375 12.4062 16 8 16C3.5625 16 0 12.4375 0 8C0 3.59375 3.5625 0 8 0C12.4062 0 16 3.59375 16 8ZM8 1C4.125 1 1 4.15625 1 8C1 11.875 4.125 15 8 15C11.8438 15 15 11.875 15 8C15 4.15625 11.8438 1 8 1Z" fill="#BE1E2D" />
            </svg>
            </Link>
            {loading && <progress></progress>}
            <div style="float:right; margin-top:18px; margin-bottom:12px; clear:both;">
                <Button style="margin-right:8px" text="Reset" color={ButtonColor.Secondary} onClick={resetOrder} />
                <Button text="Submit Order" disabled={!formValid()} onClick={submitOrder} />
            </div>
        </div>
    );
};

export default UnitPartOrder;
