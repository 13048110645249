import { FunctionalComponent, h } from 'preact';
import { LanguageService } from '../../services/language.service';
import style from './style.css';
import Button, { ButtonColor } from '../button';

export interface confirmProps {
    /**
     * The text for the search bar label. We will use the translator service to translate it for you so no need.
     */
    title: string;

    /**
     * The positive action text. Examples: "Submit" or "Delete".
     */
    positiveAction: string;

    /**
     * The negative action text. Examples: "Cancel" or "Do Nothing".
     */
    negativeAction: string;

    /**
     * Indicates whether the dialog is shown.
     */
    shown: boolean;

    /**
     * Called when dialog is closed. Be sure to set shown to false in this handler.
     * @param result Whether the user wants to perform the action or not.
     */
    onClose: (result: boolean) => void;
}

const Confirm: FunctionalComponent<confirmProps> = (props) => {
    const language = new LanguageService();
    return (
        <div class={style.modal} style={{ display: props.shown ? 'block' : 'none' }} onClick={() => props.onClose(false)}>
            <div class={style.modalContent} onClick={(event) => event.stopPropagation()}>
                <h1>{language.translate(props.title)}</h1>
                    {props.children}
                    <div style="display:flex">
                        <span style="flex:1"></span>
                        <Button text={language.translate(props.negativeAction)} color={ButtonColor.Secondary} onClick={() => props.onClose(false)} style="margin-right:12px;" />
                        <Button text={language.translate(props.positiveAction)} onClick={() => props.onClose(true)} />
                    </div>
            </div>
        </div>
    );
}

export default Confirm;